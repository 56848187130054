import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import moment from 'moment'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
	root: {},
	buttons: { textAlign: 'center' },
	text: { textAlign: 'center' },
})

const WeekSelector = (props) => {
	const { days, changeWeekFunction } = props
	const classes = useStyles()
	const dispatch = useDispatch()
	return (
		<Grid container justify="space-between" direction="row">
			<Grid className={classes.buttons} item xs={3}>
				<Tooltip title="Semna pasada">
					<IconButton
						aria-label="Semana pasada"
						onClick={() => {
						
							dispatch({ type: 'SET_API_LOADING', payload: true })

							changeWeekFunction(moment(days[0], 'DD/MM/YYYY').subtract(1, 'd'))
						}}
					>
						<NavigateBeforeIcon fontSize="large" />
					</IconButton>
				</Tooltip>
			</Grid>
			<Grid className={classes.text} item xs={6}>
				<h1>
					{days[0]} - {days[days.length - 1]}
				</h1>
			</Grid>
			<Grid className={classes.buttons} item xs={3}>
				<Tooltip title="Próxima semana">
					<IconButton
						aria-label="Próxima semana"
						onClick={() => {
							dispatch({ type: 'SET_API_LOADING', payload: true })

							changeWeekFunction(moment(days[0], 'DD/MM/YYYY"').add(7, 'd'))
						}}
					>
						<NavigateNextIcon fontSize="large" />
					</IconButton>
				</Tooltip>
			</Grid>
		</Grid>
	)
}
export default WeekSelector
