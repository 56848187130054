import React from 'react'
import { useHistory } from 'react-router-dom'
import { notifications_users_action } from '../../redux/actions/authActions'
import { notifications } from '../../redux/selectors/rootSelectors'
import clsx from 'clsx'
//Material-UI
import NotificationsIcon from '@material-ui/icons/Notifications';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/RemoveRedEyeTwoTone'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import logoM from '../../statics/M&R_logo.jpg'
import ProfileAvatar from '../Reusable/profileAvatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {
	Dashboard,
	DateRange,
	InsertChart,
	Person,
	Work,
	People,
	Settings,
	Refresh,
} from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux';

var askedForRemoval = false

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	profileIcon: {
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerNotify: {
		width: '350px',
	},
	drawerPaper: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -drawerWidth,
		maxWidth: '100%',
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
	logo: {
		display: 'block',
		margin: 'auto',
		width: '50%',
	},
}))

const Layout = (props) => {
	
	const dispatch = useDispatch();

	

	const classes = useStyles()
	const theme = useTheme()
	const [open, setOpen] = React.useState(false)

	const [ openNotify, setOpenNotify ] = React.useState(false)
	const [anchorEl, setAnchorEl] = React.useState(null)
	const user = JSON.parse(window.localStorage.getItem('user'))
	console.log("USER ==> ", user)
	React.useEffect(() => {
		dispatch(notifications_users_action())
	}, [])
	
	var notificationsUsers = useSelector((state) => notifications(state))


	const handleDrawerOpen = (e) => {
		setOpen(true)
	}
	const handleOpenNotify = (e) => {
		setOpenNotify(true)
	}
	const handleCloseNotify = (e) => {
		setOpenNotify(false)
	}
	const handleRemoveMsgNotify = (e) => {
		
		
		console.log("Hide-me(tm)")
		askedForRemoval = !askedForRemoval;
		setOpenNotify(false);
		
		
	}
	const handleDrawerClose = () => {
		setOpen(false)
	}

	const handleMenuOpen = (e) => {
		setAnchorEl(e.currentTarget)
	}
	const handleMenuClose = (e, index) => {
		setAnchorEl(null)

		if (index != -1) {
			window.localStorage.clear()
			history.push('/login')
		}
	}
	let history = useHistory()

	

	return (
		<div className={classes.root}>
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				
				<Toolbar>
					
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, open && classes.hide)}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" noWrap className={classes.title}>
						Project Management&Research
					</Typography>
					<IconButton onClick={handleMenuOpen}>
						<ProfileAvatar
							styleProps={classes.profileIcon}
							size={20}
						></ProfileAvatar>
					</IconButton>
					<NotificationsIcon 
						fontSize='large'
						onClick={handleOpenNotify}
						
					/>
				</Toolbar>

				
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={(e) => {
						handleMenuClose(e, -1)
					}}
					transformOrigin={{ horizontal: 70, vertical: 'bottom' }}
				>
					<MenuItem
						onClick={(e) => {
							handleMenuClose(e, 1)
						}}
					>
						Cerrar Sesión
					</MenuItem>
					
				</Menu>
			</AppBar>
			<Drawer
				
				id="notifs"
				variant="persistent"
				anchor="right"
				open={openNotify}
				classes={{
					paper: classes.drawerNotify,
				}}
			>
				<div style={{ display: 'flex', flexDirection:'row'}}>
					<>
					<CloseIcon style={{ margin: '15px'}} onClick={handleCloseNotify}/>
					<RemoveIcon style={{ margin: '15px'}} onClick={handleRemoveMsgNotify}  />
					<h3 >Panel de Notificaciones</h3>
					</>
				</div>
				<Divider />
				{notificationsUsers ?

				askedForRemoval ?
				(<p>Notificaciones ocultas a petición del usuario. Pulsa de nuevo <RemoveIcon onClick={handleRemoveMsgNotify}/> para volverlas visibles de nuevo</p>) :

				(
					
					notificationsUsers.map(notUs => {

						console.log(notUs, "NOTIFICATION");
						return (
						<>
							<ListItemText id={notUs.user} primary={ (typeof(notUs.ts) === "undefined" ? "" : notUs.ts + " => ") + notUs.message} />
							<Divider />
						</>
						)

					

					}) )
				: <p>Cargando...</p> }

				
			</Drawer>
			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="left"
				open={open}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div className={classes.drawerHeader}>
					<>
						<img className={classes.logo} src={logoM} alt="Logo" />

						<IconButton onClick={handleDrawerClose}>
							{theme.direction === 'ltr' ? (
								<ChevronLeftIcon />
							) : (
								<ChevronRightIcon />
							)}
						</IconButton>
					</>
				</div>
				<Divider />
				<List>
					<ListItem
						button
						key={'Dashboard'}
						onClick={() => {
							setOpen(false)
							history.push('/')
						}}
					>
						<ListItemIcon>
							<Dashboard />
						</ListItemIcon>
						<ListItemText primary={'Dashboard'} />
					</ListItem>
					<ListItem
						button
						key={'Calendario'}
						onClick={() => {
							setOpen(false)
							history.push('/calendario')
						}}
					>
						<ListItemIcon>
							<DateRange />
						</ListItemIcon>
						<ListItemText primary={'Calendario'} />
					</ListItem>{' '}
					<ListItem
						button
						key={'Reportes'}
						onClick={() => {
							setOpen(false)
							history.push('/reportes')
						}}
					>
						<ListItemIcon>
							<InsertChart />
						</ListItemIcon>
						<ListItemText primary={'Reportes'} />
					</ListItem>{' '}
					<ListItem
						button
						key={'Perfil'}
						onClick={() => {
							setOpen(false)
							history.push('/perfil')
						}}
					>
						<ListItemIcon>
							<Person />
						</ListItemIcon>
						<ListItemText primary={'Perfil'} />
					</ListItem>
				</List>
				<Divider />
				{user.is_project_manager && ( 

				<>
				<List>
				
				<ListItem
							button
							key={'Mpa'}
							onClick={() => {
								setOpen(false)
								history.push('/mpa')
							}}
						>
							<ListItemIcon>
								<Work />
							</ListItemIcon>
							<ListItemText primary={'Mis Proyectos Asignados'} />
						</ListItem>
						</List>
						<Divider />
				
				</>
				)}
				{user.is_staff && (
					<List>
						<ListItem
							button
							key={'Proyectos'}
							onClick={() => {
								setOpen(false)
								history.push('/proyectos')
							}}
						>
							<ListItemIcon>
								<Work />
							</ListItemIcon>
							<ListItemText primary={'Proyectos'} />
						</ListItem>
						<ListItem
							button
							key={'Usuarios'}
							onClick={() => {
								setOpen(false)
								history.push('usuarios')
							}}
						>
							<ListItemIcon>
								<People />
							</ListItemIcon>
							<ListItemText primary={'Usuarios'} />
						</ListItem>
						<ListItem
							button
							key={'Ajustes'}
							onClick={() => {
								setOpen(false)
								history.push('/ajustes')
							}}
						>
							<ListItemIcon>
								<Settings />
							</ListItemIcon>
							<ListItemText primary={'Ajustes'} />
						</ListItem>
					</List>
				)}
			</Drawer>
			<main
				className={clsx(classes.content, {
					[classes.contentShift]: open,
				})}
			>
				<div className={classes.drawerHeader} />
				{props.children}
			</main>
		</div>
	)
}
export default Layout
