import * as types from '../../const/actionTypes'

export const get_categories_action = () => {
	return {
		type: types.GET_CATEGORIES,
	}
}
export const get_my_categories_action = () => {
	return {
		type: types.GET_MY_CATEGORIES,
	}
}

export const get_total_hours_action = (day) => {
	
	return {
		type: types.TOTAL_HOURS_PROJECT,
		day,
	}
}

export const update_project_action = (payload, id, setOpenModal) => {
	return {
		type: types.UPDATE_PROJECT,
		payload,
		id,
		setOpenModal,
	}
}
export const create_project_action = (payload, setOpenModal) => {
	return {
		type: types.CREATE_PROJECT,
		payload,
		setOpenModal,
	}
}

export const create_project_user_action = (payload, setOpenModal) => {
	return {
		type: types.CREATE_PROJECT_USER,
		payload,
		setOpenModal,
	}
}

export const delete_project_action = (payload, id, setOpenModal) => {
	return {
		type: types.DELETE_PROJECT,
		payload,
		id,
		setOpenModal,
	}
}

export const update_activity_action = (payload, id, setOpenModal) => {
	return {
		type: types.UPDATE_ACTIVITY,
		payload,
		id,
		setOpenModal,
	}
}
export const create_activity_action = (payload, setOpenModal) => {
	return {
		type: types.CREATE_ACTIVITY,
		payload,
		setOpenModal,
	}
}

export const delete_activity_action = (payload, id, setOpenModal) => {
	return {
		type: types.DELETE_ACTIVITY,
		payload,
		id,
		setOpenModal,
	}
}
export const assign_users_project_action = (
	payload,
	idProject,
	setOpenModal,
) => {
	return {
		type: types.ASSIGN_USERS_PROJECT,
		payload,
		idProject,
		setOpenModal,
	}
}
