import { combineReducers } from 'redux'

// Reducers
import authReducer from './authReducer'
import projectsReducer from './projectsReducer'
import registersReducer from './registersReducer'
import reportsSlice from './reportsSlice'
import settingsSlice from './settingsSlice'
import userRegisterIDReducer from './userRegisterIDReducer'
import historicalSuccesReducer from './historicalSuccesReducer'
import totalProject from './totalProject'
import notificationReducer from './notificationReducer'

const rootReducer = combineReducers({
	authReducer,
	projectsReducer,
	registersReducer,
	reportsSlice,
	settingsSlice,
	userRegisterIDReducer,
	historicalSuccesReducer,
	totalProject,
	notificationReducer,
})

export default rootReducer
