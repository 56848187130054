import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Layout from '../Layout/layout.jsx'

const PrivateRoute = ({ component: Component, ...rest }) => {
	const token = localStorage.getItem('access')

	return (
		<Route
			{...rest}
			render={(props) =>
				token ? (
					<Layout>{<Component {...props} />}</Layout>
				) : (
					<Redirect
						to={{ pathname: '/login', state: { from: props.location } }}
					/>
				)
			}
		/>
	)
}
export default PrivateRoute
