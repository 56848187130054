import { useEffect, useState } from 'react'
import moment from 'moment'
import { getWeekArrayByDay } from '../../utils/dates.js'

import { useDispatch } from 'react-redux'
import { delete_day_registers_action, get_registers_action } from '../../redux/actions/registersActions'
//Material-UI
// import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Swal from 'sweetalert2'

//Components
import PaperDay from '../Calendario/paperDay.jsx'
import CrearRegistro from './crearRegistro.jsx';
import ModificaRegistro from './modifcaRegistro.jsx'

import { selectSettings } from '../../redux/reducers/settingsSlice.js'
import {  useSelector } from 'react-redux'

import {
	getSettings as getSettingsAction,
} from '../../redux/reducers/settingsSlice'




const CalendarGraph = (props) => {
	//const classes = useStyles()
	const { days, data, dataStructure, myInfo } = props

	const user =  myInfo.payload.authReducer.user.is_staff
	const [dataModal, setDataModal] = useState(null)
	const [openModal, setOpenModal] = useState(false)
	const [openEdit, setOpenEdit] = useState(false)
	const [info, setInfo] = useState('')
	const [daySelected, setDaySelected] = useState();
	const [ modifyData, setModifyData] = useState()
	
	//const [ errMinMen, setErrMinMen ] = useState(`La unidad minima de volcado de horas es ${values[2]}`)

	const [ openModify, setOpenModify ] = useState(false);
	const [values, setValues] = useState([])
	const settings = useSelector((state) => selectSettings(state))

	

	useEffect(() => {
		dispatch(getSettingsAction())
	}, [])
	useEffect(() => {
		if (settings) {
			setValues(settings.map((setting) => setting.value))
		}
	}, [settings])
	

	

	const dispatch = useDispatch()
	useEffect(() => {
		
	}, [data])

	

	const dataModify = (day) => {
			
			const editData = [...dataStructure];
			const dataNew = [...data[day]]
			
			for (let i = 0; i < editData.length; i++) {
				for (let r = 0; r < dataNew.length; r++) {
					if ( editData[i].id === dataNew[r].category.id ) {
						editData[i] = dataNew[r].category
					}
				}
			}
			setModifyData(editData)
			
	}



	return (
		<>
			<Grid container spacing={3}>
				{days.map((day, i) => {
					var localLocale = moment(day, 'DD/MM/YYYY').format(
						'dddd DD MMMM YYYY',
					)
					
					return (
						<Grid key={i + 'gridPaper'} item md={3} sm={6} xs={12}>
							<PaperDay
								key={i + 'papperDay'}
								infoDay={localLocale}
								workInfo={day in data ? data[day] : false}
								timeout={1000 + 500 * i}
								onClick={() => { 
									if (!(day in data)) {
		
										setDataModal(day)
										setOpenModal(true)
									} else {
										
										Swal.fire({
											title:
												'¿Quieres eliminar o editar el ' +
												day +
												'?',
											showDenyButton: true,
											confirmButtonText: `Editar`,
											denyButtonText: `Eliminar`,
										}).then((result) => {
											/* Read more about isConfirmed, isDenied below */
											if (result.isDenied) {
												const days = getWeekArrayByDay(
													moment(day, 'DD/MM/YYYY"'),
												)
												
												const startDate = moment(days[0], 'DD/MM/YYYY').format(
													'Y-MM-DD',
												)
												const endDate = moment(
													days[days.length - 1],
													'DD/MM/YYYY',
												).format('Y-MM-DD')
												dispatch(
													delete_day_registers_action(
														moment(day, 'DD/MM/YYYY').format('Y-MM-DD'),
														startDate,
														endDate,
													)
												)
												
											} else if (result.isConfirmed) {
												
													dataModify(day)
													
													setOpenModify(true)
													setInfo(day in data ? data[day] : false)
													var localLocale = moment(day, 'DD/MM/YYYY');
													setDaySelected(day)
												
											}
										})
									}
								}}
							/>
						</Grid>
					)
				})}
			</Grid>
			{ (values) && (
			<CrearRegistro
				openModal={openModal}
				setOpenModal={setOpenModal}
				dataStructure={dataStructure}
				registerDay={dataModal}
				data={data}
				max={values[1]}
				msnErr={` No se pueden añadir más de ${values[1]} horas, el minimo es ${values[2]}`}
				errMinMen={`La unidad minima de volcado de horas es ${values[2]}`}
				min={values[2] ? values[2] : null}
			/>
			)}	
			{(info && daySelected) && ( <ModificaRegistro
				openModify={openModify}
				setOpenModify={setOpenModify}
				data={data}  
				info={info}
				setInfo={setInfo}
				dataStructure={dataStructure}
				registerDay={dataModal} 
				day={daySelected}
				setDay={setDaySelected}
				newData={modifyData}
				max={values[1]}
				min={values[2] ? values[2] : null}
				errMinMen={`La unidad minima de volcado de horas es ${values[2]}`}
				
				
			/>)}
		</>
	)
}

export default CalendarGraph
