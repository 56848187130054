import { put, call, takeLatest } from 'redux-saga/effects'
import { apiCall } from '../apiCall'
import * as types from '../../const/actionTypes'
import * as Services from '../services/authServices.js'
import Swal from 'sweetalert2'

export function* get_categories_sagas({ payload }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			'projects/categories/',
			{},
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'GET',
		)
		if (res.status === 200) {
			yield put({ type: types.GET_CATEGORIES_SUCCESS, payload: res.data })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}
export function* get_total_hours_sagas({ day }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			'registers/hours?day=' + day,
			{},
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'GET',
		)

		if (res.status === 200) {
			yield put({ type: types.TOTAL_HOURS_PROJECT_SUCCESS, payload: res.data })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}
export function* get_my_categories_sagas({ payload }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			'projects/my-categories/',
			{},
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'GET',
		)
		if (res.status === 200) {
			yield put({ type: types.GET_MY_CATEGORIES_SUCCESS, payload: res.data })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

export function* update_project_sagas({ payload, id, setOpenModal }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			'projects/projects/' + id + '/',
			payload,
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'PUT',
		)
		if (res.status === 200) {
			yield put({ type: types.UPDATE_PROJECT_SUCCESS })
			Swal.fire({
				icon: 'success',
				title: 'Éxito',
				text: 'Se actualizó correctamente',
			}).then(() => {
				setOpenModal(false)
			})
			yield put({ type: types.GET_CATEGORIES })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}
export function* create_project_sagas({ payload, setOpenModal }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			'projects/projects/',
			payload,
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'POST',
		)
		if (res.status === 201) {
			yield put({ type: types.CREATE_PROJECT_SUCCESS })
			Swal.fire({
				icon: 'success',
				title: 'Éxito',
				text: 'Se creó correctamente',
			}).then(() => {
				setOpenModal(false)
			})

			yield put({ type: types.GET_CATEGORIES })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

export function* create_project_user_sagas({ payload, setOpenModal }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			'projects/add-project/',
			payload,
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'POST',
		)
		if (res.status === 201) {
			yield put({ type: types.CREATE_PROJECT_USER_SUCCESS })
			Swal.fire({
				icon: 'success',
				title: 'Éxito',
				text: 'Se creó correctamente',
			}).then(() => {
				setOpenModal(false)
			})

			yield put({ type: types.GET_MY_CATEGORIES })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

export function* delete_project_sagas({ payload, id, setOpenModal }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			'projects/projects/' + id + '/',
			payload,
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'DELETE',
		)
		if (res.status === 200) {
			yield put({ type: types.DELETE_PROJECT_SUCCESS })
			Swal.fire({
				icon: 'success',
				title: 'Éxito',
				text: 'Se eliminó correctamente',
			}).then(() => {
				setOpenModal(false)
			})
			yield put({ type: types.GET_CATEGORIES })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

export function* update_activity_sagas({ payload, id, setOpenModal }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			'projects/activities/' + id + '/',
			payload,
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'PUT',
		)
		if (res.status === 200) {
			yield put({ type: types.UPDATE_ACTIVITY_SUCCESS })
			Swal.fire({
				icon: 'success',
				title: 'Éxito',
				text: 'Se actualizó correctamente',
			}).then(() => {
				setOpenModal(false)
			})
			yield put({ type: types.GET_CATEGORIES })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}
export function* create_activity_sagas({ payload, setOpenModal }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {


		console.log("SAGA_PAYLOAD " + payload)
		const res = yield call(
			apiCall,
			'projects/activities/',
			payload,
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'POST',
		)
		if (res.status === 201) {
			yield put({ type: types.CREATE_ACTIVITY_SUCCESS })
			Swal.fire({
				icon: 'success',
				title: 'Éxito',
				text: 'Se creó correctamente',
			}).then(() => {
				setOpenModal(false)
			})
			yield put({ type: types.GET_CATEGORIES })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

export function* delete_activity_sagas({ payload, id, setOpenModal }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			'projects/activities/' + id + '/',
			payload,
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'DELETE',
		)
		if (res.status === 200) {
			yield put({ type: types.DELETE_ACTIVITY_SUCCESS })
			Swal.fire({
				icon: 'success',
				title: 'Éxito',
				text: 'Se eliminó correctamente',
			}).then(() => {
				setOpenModal(false)
			})
			yield put({ type: types.GET_CATEGORIES })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}
export function* assign_users_project_sagas({
	payload,
	idProject,
	setOpenModal,
}) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			'projects/assign/' + idProject + '/',
			{ users_on_project: payload },
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'PUT',
		)
		if (res.status === 200) {
			yield put({ type: types.ASSIGN_USERS_PROJECT_SUCCESS })
			Swal.fire({
				icon: 'success',
				title: 'Éxito',
				text: 'Se asignó correctamente',
			}).then(() => {
				setOpenModal(false)
			})
			yield put({ type: types.GET_CATEGORIES })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}
export default function* projectsSaga() {
	yield takeLatest(types.GET_CATEGORIES, get_categories_sagas)
	yield takeLatest(types.GET_MY_CATEGORIES, get_my_categories_sagas)
	yield takeLatest(types.UPDATE_PROJECT, update_project_sagas)
	yield takeLatest(types.CREATE_PROJECT, create_project_sagas)
	yield takeLatest(types.CREATE_PROJECT_USER, create_project_user_sagas)
	yield takeLatest(types.DELETE_PROJECT, delete_project_sagas)
	yield takeLatest(types.UPDATE_ACTIVITY, update_activity_sagas)
	yield takeLatest(types.CREATE_ACTIVITY, create_activity_sagas)
	yield takeLatest(types.DELETE_ACTIVITY, delete_activity_sagas)
	yield takeLatest(types.ASSIGN_USERS_PROJECT, assign_users_project_sagas)
	yield takeLatest(types.TOTAL_HOURS_PROJECT, get_total_hours_sagas)
}
