import { put, call, takeLatest } from 'redux-saga/effects'
import { apiCall } from '../apiCall'
import * as types from '../../const/actionTypes'

import * as Services from '../services/authServices.js'
import Swal from 'sweetalert2'

export function* assigned_projects_sagas({ payload }) {
	console.log(payload, ' assigned_projects dispatched')

}

export function* get_registro_historico_sagas({ payload }) {
	console.log(payload, ' step 2')
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const registroHistorico = yield call(
			apiCall,
			'registers/user/' + payload,
			{},
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'GET',
		)
		if (registroHistorico.status === 200) {
			const data = yield put({
				type: types.GET_HISTORIAL_SUCCESS,
				data: registroHistorico.data,
			})
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
	console.log(payload, ' step 3')
}

export function* notifications_users_sagas({ payload }) {

	console.log(payload, ' notifications_users dispatched')
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const notifications = yield call(
			apiCall,
			'notifications/all',
			{},
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'GET',
		)
		if (notifications.status === 200) {
			yield put({
				type: types.NOTIFICATIONS_USER_SUCCESS,
				payload: notifications.data,
			})
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

export default function* authRegisterSaga() {
	yield takeLatest(types.GET_HISTORIAL, get_registro_historico_sagas)
	yield takeLatest(types.NOTIFICATIONS_USER, notifications_users_sagas)
	yield takeLatest(types.ASSIGNED_PROJECTS, assigned_projects_sagas)
}
