import { get } from 'lodash'
//AUTH
export const user = (state) => get(state, 'authReducer.user')
export const logged = (state) => get(state, 'authReducer.logged')
export const error = (state) => get(state, 'authReducer.error')
export const tokenAccess = (state) => get(state, 'authReducer.tokenAccess')
export const tokenRefresh = (state) => get(state, 'authReducer.tokenRefresh')
export const isValid = (state) => get(state, 'authReducer.isValid')
export const politicasPrivacidadResult = (state) =>
	get(state, 'authReducer.politicasPrivacidad')
export const users = (state) => get(state, 'authReducer.users')
export const generalLoading = (state) =>
	get(state, 'authReducer.generalLoading')

//PROJECTS
export const categoriesInfo = (state) =>
	get(state, 'projectsReducer.CategoriesInfo')
export const myCategoriesInfo = (state) =>
	get(state, 'projectsReducer.MyCategoriesInfo')

//REGISTERS
export const weekRegisters = (state) =>
	get(state, 'registersReducer.WeekRegisters')

export const RegisterData = (state) => {
	console.log(state)
	get(state, 'registerReducer.RegistersData')
}

export const RegistroHistorico = (state) =>
	get(state, 'historicalSuccesReducer.HistorialRegistrado')

export const totalHours = (state) => get(state, 'totalProject.totalHours')

export const notifications = (state) =>
	get(state, 'notificationReducer.notifications')
