import { put, call, takeLatest } from 'redux-saga/effects'
import { apiCall } from '../apiCall'
import * as types from '../../const/actionTypes'
import * as Services from '../services/authServices.js'
import Swal from 'sweetalert2'
import {
	getSettings,
	setSettings,
	updateSetting,
} from '../reducers/settingsSlice'

export function* get_settings_sagas({ payload }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			`settings/settings`,
			{},
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'GET',
		)
		if (res.status === 200) {
			yield put({
				type: setSettings.type,
				payload: res.data,
			})
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

export function* update_setting_sagas({ payload }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			`settings/settings/${payload.id}/`,
			payload,
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'PUT',
		)
		if (res.status === 200) {
			Swal.fire({
				icon: 'success',
				title: 'Éxito',
				text: 'Se actualizó correctamente',
			}).then(() => {})
			yield put({ type: getSettings.type })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

export default function* settingsSaga() {
	yield takeLatest(getSettings.type, get_settings_sagas)
	yield takeLatest(updateSetting.type, update_setting_sagas)
}
