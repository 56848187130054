
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { REDUCER_EXCEPTIONS } from "../../const/actionTypes.js";

// Reducers que seran los encargados de manejar la data de la aplicaci�n
import rootReducer from "../reducers/rootReducers";
// en cargado de controlar los side effects o api calls
import rootSaga from "../sagas/rootSagas";

const validateToken = (store) => (next) => (action) => {
  const isReducerInExcepts = REDUCER_EXCEPTIONS.includes(action.type);
  if (isReducerInExcepts) {
    next(action);
  } else {
    // console.log("Ingresando al Middleware...");
    new Promise((resolve, reject) => {
      store.dispatch({
        type: "VERIFY_TOKEN",
        payload: {
          store,
          next,
          action,
          from: `intercept redux call: ${JSON.stringify(next)}`,
        },
      });
      setTimeout(function () {
        resolve(next(action));
      }, 1000);
    });
  }
};

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(validateToken),
    applyMiddleware(sagaMiddleware)
  )
);

const configureStore = () => {
  return {
    ...store,
    runSaga: sagaMiddleware.run(rootSaga, {}),
  };
};

export default configureStore;
