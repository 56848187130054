import { createSlice } from '@reduxjs/toolkit'

export const settingsSlice = createSlice({
	name: 'settings',
	initialState: {
		settings: null,
	},
	reducers: {
		getSettings() {},
		setSettings: (state, { payload }) => {
			state.settings = payload
		},
		updateSetting() {},
	},
})

// Action creators are generated for each case reducer function
export const { getSettings, setSettings, updateSetting } = settingsSlice.actions
export const selectSettings = (state) => state.settingsSlice.settings

export default settingsSlice.reducer
