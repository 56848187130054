import { put, call, takeLatest, takeEvery, fork } from 'redux-saga/effects'
import { apiCall } from '../apiCall'
import * as types from '../../const/actionTypes'
import * as Services from '../services/authServices.js'
import Swal from 'sweetalert2'

export function* login({ payload }) {
	try {
		const tokens = yield call(
			apiCall,
			'auth/token/',
			{
				email: payload.email,
				password: payload.password,
			},
			{
				'Content-Type': 'application/json',
			},
			'POST',
		)
		// console.log({ TOKENS_PROVE: tokens })
		if (tokens.status === 200) {
			yield call(Services.setAccessToken, tokens.data.access)
			yield call(Services.setRefreshToken, tokens.data.refresh)
			yield call(getUser, tokens.data.access)
			yield put({ type: types.LOGIN_SUCCESS, tokens })
			window.location.href = '/'
		}
	} catch (e) {
		yield put({ type: types.LOGIN_ERROR, e })
		// console.log(e)
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
		})
	}
}

export function* verifyToken({ payload }) {
	try {
		const results = yield call(
			apiCall,
			'auth/token/verify/',
			{
				token: Services.getAccessToken(),
			},
			{
				'Content-Type': 'application/json',
				// MensajeOnError: "La sesión ha expirado",
			},
			'POST',
		)
		console.log('Verficando ...')
		if (JSON.stringify(results.data) === '{}') {
			yield put({ type: types.VALID_TOKEN, results })
		} else {
			let refreshToken = yield call(Services.getRefreshToken)
			yield fork(refreshNewToken, refreshToken)
		}
	} catch (e) {
		console.error({ e })
		if (e.request.status === 401) {
			let refreshToken = yield call(Services.getRefreshToken)
			yield fork(refreshNewToken, refreshToken)
		} else {
			yield put({ type: types.INVALID_TOKEN, e })
			console.log(e.request)
			console.log('HUBO UN ERROR AL VALIDAR EL TOKEN Y NO SE REFRESCARA')
		}
		// let refreshToken = yield call(Services.getRefreshToken)
		// yield fork(refreshNewToken, refreshToken)
	}
}

export function* refreshNewToken(tokenRefresh) {
	console.log('refrescando...')
	console.log(tokenRefresh)
	try {
		const newToken = yield call(
			apiCall,
			'auth/token/refresh/',
			{
				refresh: tokenRefresh,
			},
			{
				Authorization: '',
				'Content-Type': 'application/json',
			},
			'POST',
		)

		yield call(Services.setAccessToken, newToken.data.access)
		yield put({ type: types.REFRESH_TOKEN_SUCCESS, newToken })
	} catch (e) {
		yield put({ type: types.LOGOUT, e })
		console.log(e)
	}
}

function* getUser(accessToken) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const userInfo = yield call(
			apiCall,
			'auth/me',
			{},
			{
				Authorization: 'Bearer ' + accessToken,
			},
			'GET',
		)

		yield call(Services.setUser, userInfo.data)
		yield put({ type: types.GET_USER_INFO_SUCCESS, userInfo })
	} catch (error) {
		yield put({ type: types.GET_REQUEST_ERROR, error })
		console.log(error)
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

function* update_my_user_sagas({ payload, refresh }) {
	try {
		const tokens = yield call(
			apiCall,
			'auth/me/',
			payload,
			{
				Authorization: 'Bearer ' + Services.getAccessToken(),
				'Content-Type': 'application/json',
			},
			'PUT',
		)
		// console.log({ TOKENS_PROVE: tokens })

		if (tokens.status === 200) {
			yield call(getUser, Services.getAccessToken())
			Swal.fire({
				title: 'Éxito',
				text: 'Se actualizó correctamente',
				icon: 'success',
				confirmButtonText: 'Ok',
			}).then((isConfirm) => {
				if (isConfirm) {
					// window.location.href = '/perfil'
					// setOpenModal(false)
				}
			})
			refresh()
		}
	} catch (error) {
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
		})
	}
}

function* getUsers() {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const usersInfo = yield call(
			apiCall,
			'auth/users/',
			{},
			{
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'GET',
		)

		yield put({ type: types.GET_USERS_SUCCESS, payload: usersInfo.data })
	} catch (error) {
		yield put({ type: types.SET_API_ERROR, error })
		console.log(error)
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

function* forgotPasswordSaga({ payload }) {
	try {
		const tokens = yield call(
			apiCall,
			'auth/password_reset/',
			payload,
			{
				'Content-Type': 'application/json',
			},
			'POST',
		)
		// console.log({ TOKENS_PROVE: tokens })
		if (tokens.status === 200) {
			Swal.fire({
				title: 'Éxito',
				text: 'Se te envio un email con las instrucciones para reestablecer tu contraseña',
				icon: 'success',
				confirmButtonText: 'Ok',
			})
		}
	} catch (error) {
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
		})
	}
}
function* restorePasswordSaga({ payload }) {
	try {
		const tokens = yield call(
			apiCall,
			'auth/password_reset/confirm/',
			payload,
			{
				'Content-Type': 'application/json',
			},
			'POST',
		)
		// console.log({ TOKENS_PROVE: tokens })
		if (tokens.status === 200) {
			Swal.fire({
				title: 'Éxito',
				text: 'Se ha reestablecido tu contraseña',
				icon: 'success',
				confirmButtonText: 'Ok',
			}).then((isConfirm) => {
				if (isConfirm) {
					window.location.href = '/'
				}
			})
		}
	} catch (error) {
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
		})
	}
}
function* update_user_sagas({ payload, setOpenModal }) {
	try {
		const tokens = yield call(
			apiCall,
			'auth/users/' + payload.id + '/',
			payload,
			{
				Authorization: 'Bearer ' + Services.getAccessToken(),
				'Content-Type': 'application/json',
			},
			'PUT',
		)
		// console.log({ TOKENS_PROVE: tokens })

		if (tokens.status === 200) {
			yield call(getUser, Services.getAccessToken())
			Swal.fire({
				title: 'Éxito',
				text: 'Se actualizó correctamente',
				icon: 'success',
				confirmButtonText: 'Ok',
			}).then((isConfirm) => {
				if (isConfirm) {
					// window.location.href = '/perfil'
					setOpenModal(false)
				}
			})

			yield put({ type: types.GET_USERS })
		}
	} catch (error) {
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
		})
	}
}

export function* create_user_sagas({ payload, setOpenModal }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			'auth/create/',
			payload,
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'POST',
		)

		if (res.status === 201) {
			yield put({ type: types.CREATE_USER_SUCCESS })
			Swal.fire({
				icon: 'success',
				title: 'Éxito',
				text: 'Se creó correctamente',
			}).then(() => {
				setOpenModal(false)
			})
			yield put({ type: types.GET_USERS })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

export default function* authSaga() {
	yield takeLatest(types.LOGIN, login)
	yield takeEvery(types.VERIFY_TOKEN, verifyToken)
	yield takeLatest(types.REFRESH_TOKEN, refreshNewToken)
	yield takeLatest(types.FORGOT_PASSWORD, forgotPasswordSaga)
	yield takeLatest(types.RESTORE_PASSWORD, restorePasswordSaga)
	yield takeLatest(types.GET_USERS, getUsers)
	yield takeLatest(types.UPDATE_USER, update_user_sagas)
	yield takeLatest(types.UPDATE_MY_USER, update_my_user_sagas)
	yield takeLatest(types.CREATE_USER, create_user_sagas)
}
