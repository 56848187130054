import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import CalendarGraph from '../../components/Calendario/calendario.jsx'
import WeekSelector from '../../components/Calendario/weekSelector.jsx'
import { getWeekArrayByDay } from '../../utils/dates.js'
import moment from 'moment'

//Actions
import { get_my_categories_action } from '../../redux/actions/projectsActions'
import { get_registers_action } from '../../redux/actions/registersActions'
import {userInfo} from '../../redux/actions/authActions';


// Selectors
import {
	myCategoriesInfo,
	weekRegisters,
} from '../../redux/selectors/rootSelectors'
import { GET_USERS } from '../../const/actionTypes.js'

const useStyles = makeStyles({
	root: { height: '80vh' },
})

const Calendario = () => {
	const classes = useStyles()
	const [days, setDays] = useState(getWeekArrayByDay(moment()))
	const dispatch = useDispatch()
	const myCategories = useSelector((state) => myCategoriesInfo(state))
	const registers = useSelector((state) => weekRegisters(state))
	const myInfo = useSelector((state) => userInfo(state))
	// console.log(registers, "REGISTROS DE LA SEMANA")
	// console.log(myCategories, "MIS CATEGORIAS")

	useEffect(() => {
		dispatch(get_my_categories_action())
		dispatch(
			get_registers_action({
				startDate: moment(days[0], 'DD/MM/YYYY').format('Y-MM-DD'),
				endDate: moment(days[days.length - 1], 'DD/MM/YYYY').format('Y-MM-DD'),
			}),
		)
	}, [])

	

	const changeWeek = (dateToChange) => {
		const newDays = getWeekArrayByDay(dateToChange)
		setDays(newDays)
		dispatch(
			get_registers_action({
				startDate: moment(newDays[0], 'DD/MM/YYYY').format('Y-MM-DD'),
				endDate: moment(newDays[newDays.length - 1], 'DD/MM/YYYY').format(
					'Y-MM-DD',
				),
			}),
		)
	}

	return (
		<Container className={classes.root} maxWidth="lg">
			<WeekSelector days={days} changeWeekFunction={changeWeek} />
			<CalendarGraph
				days={days}
				data={registers}
				dataStructure={myCategories}
				myInfo={myInfo}
			/>
		</Container>
	)
}

export default Calendario
