//AUTH TYPES
export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const LOGOUT = 'LOGOUT'

export const VERIFY_TOKEN = 'VERIFY_TOKEN'
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS'
export const VERIFY_TOKEN_ERROR = 'VERIFY_TOKEN_ERROR'

export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR'

export const INVALID_TOKEN = 'INVALID_TOKEN'
export const VALID_TOKEN = 'VALID_TOKEN'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const RESTORE_PASSWORD = 'RESTORE_PASSWORD'

//USER TYPES
export const GET_USER_INFO = 'GET_USER_INFO'
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS'
export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR'
export const REFRESH_USER = 'REFRESH_USER'
export const REFRESH_USER_SUCCESS = 'REFRESH_USER_SUCCESS'
export const REFRESH_USER_ERROR = 'REFRESH_USER_ERROR'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_MY_USER = 'UPDATE_MY_USER'

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'

//Proyectos
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCESS'

export const GET_MY_CATEGORIES = 'GET_MY_CATEGORIES'
export const GET_MY_CATEGORIES_SUCCESS = 'GET_MY_CATEGORIES_SUCESS'

export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS'

export const CREATE_PROJECT = 'CREATE_PROJECT'
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'

export const CREATE_PROJECT_USER = 'CREATE_PROJECT_USER'
export const CREATE_PROJECT_USER_SUCCESS = 'CREATE_PROJECT_USER_SUCCESS'

export const DELETE_PROJECT = 'DELETE_PROJECT'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'

export const ASSIGN_USERS_PROJECT = 'ASSIGN_USERS_PROJECT'
export const ASSIGN_USERS_PROJECT_SUCCESS = 'ASSIGN_USERS_PROJECT_SUCCESS'

export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY'
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS'

export const CREATE_ACTIVITY = 'CREATE_ACTIVITY'
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS'

export const DELETE_ACTIVITY = 'DELETE_ACTIVITY'
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS'

export const TOTAL_HOURS_PROJECT = 'TOTAL_HOURS_PROJECT'
export const TOTAL_HOURS_PROJECT_SUCCESS = 'TOTAL_HOURS_PROJECT_SUCCESS'

//REGISTROS
export const GET_REGISTERS = 'GET_REGISTERS'
export const GET_REGISTERS_SUCCESS = 'GET_REGISTERS_SUCCESS'

export const CREATE_REGISTERS = 'CREATE_REGISTERS'
export const CREATE_REGISTERS_SUCCESS = 'CREATE_REGISTERS_SUCCESS'

export const UPDATE_REGISTERS = 'UPDATE_REGISTERS'
export const UPDATE_REGISTERS_SUCCESS = 'UPDATE_REGISTERS_SUCCESS'

export const DELETE_DAY_REGISTERS = 'DELETE_DAY_REGISTERS'
export const MODIFY_DAY_REGISTERS = 'MODIFY_DAY_REGISTERS'

// HISTORIAL USUARIOS
export const GET_HISTORIAL = 'GET_HISTORIAL'
export const GET_HISTORIAL_SUCCESS = 'GET_HISTORIAL_SUCCESS'

//REPORTES
export const GET_PROJECTS_REPORT = 'GET_PROJECTS_REPORT'
export const GET_PROJECTS_REPORT_SUCCESS = 'GET_PROJECTS_REPORT_SUCCESS'

export const GET_HISTORY_REPORT = 'GET_HISTORY_REPORT'
export const GET_HISTORY_REPORT_SUCCESS = 'GET_HISTORY_REPORT_SUCCESS'

//GENERIC
export const SET_API_ERROR = 'SET_API_ERROR'
export const SET_API_LOADING = 'SET_API_LOADING'
export const SET_API_LOADING_FINISH = 'SET_API_LOADING'

export const GET_REQUEST_ERROR = 'GET_REQUEST_ERROR'

export const REDUCER_EXCEPTIONS = [
	LOGIN,
	FORGOT_PASSWORD,
	VERIFY_TOKEN,
	REFRESH_TOKEN,
	RESTORE_PASSWORD,
	'reports/setColorsReports',
	SET_API_LOADING,
]

// NOTIFICATIONS

export const NOTIFICATIONS_USER = 'NOTIFICATIONS_USER'
export const NOTIFICATIONS_USER_SUCCESS = 'NOTIFICATIONS_USER_SUCCESS'

// ASSIGNED PROJECTS

export const ASSIGNED_PROJECTS = 'ASSIGNED_PROJECTS'
