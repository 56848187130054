import * as types from '../../const/actionTypes'

export const login = (payload) => {
	return {
		type: types.LOGIN,
		payload: payload,
	}
}

export const userInfo = (payload) => {
	return {
		type: types.GET_USER_INFO,
		payload: payload,
	}
}

export const forgotPassword = (payload) => {
	return {
		type: types.FORGOT_PASSWORD,
		payload,
	}
}

export const restorePassword = (payload) => {
	return {
		type: types.RESTORE_PASSWORD,
		payload,
	}
}

export const getUsers = () => {
	return {
		type: types.GET_USERS,
	}
}

export const update_user_action = (payload, setOpenModal) => {
	return {
		type: types.UPDATE_USER,
		payload,
		setOpenModal,
	}
}
export const update_my_user_action = (payload, refresh) => {
	return {
		type: types.UPDATE_MY_USER,
		payload,
		refresh,
	}
}

export const create_user_action = (payload, setOpenModal) => {
	return {
		type: types.CREATE_USER,
		payload,
		setOpenModal,
	}
}

export const notifications_users_action = () => {
	return {
		type: types.NOTIFICATIONS_USER,
	}
}
