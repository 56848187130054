import { createSlice } from '@reduxjs/toolkit'

const availableColors = [
	'rgb(232, 193, 160)',
	'rgb(244, 117, 96)',
	'rgb(241, 225, 91)',
	'rgb(232, 168, 56)',
	'rgb(97, 205, 187)',
	'rgb(190, 186, 218)',
	'rgb(251, 128, 114)',
	'rgb(128, 177, 211)',
	'rgb(253, 180, 98)',
	'rgb(179, 222, 105)',
	'rgb(252, 205, 229)',
	'rgb(217, 217, 217)',
	'rgb(188, 128, 189)',
	'rgb(141, 211, 199)',
	'rgb(255, 255, 179)',
	'rgb(204, 235, 197)',
	'rgb(255, 237, 111)',
	'rgb(151, 227, 213)',
]

export const reportsSlice = createSlice({
	name: 'reports',
	initialState: {
		projectsReport: {},
		historyReport: {},
		colors: {},
	},
	reducers: {
		getProjectsReport() {},
		getProjectsReportSuccess() {},
		setProjectsReport: (state, { payload }) => {
			// let newProjectsReport = {...projectsReport}
			// newProjectsReport[payload.rangeDates]=payload.data
			// state={...state, projectsReport:newProjectsReport}
			state.projectsReport[payload.rangeDates] = payload.data
		},
		getHistoryReport() {},
		getHistoryReportSuccess() {},
		setHistoryReport: (state, { payload }) => {
			// let newProjectsReport = {...projectsReport}
			// newProjectsReport[payload.rangeDates]=payload.data
			// state={...state, projectsReport:newProjectsReport}
			state.historyReport[payload.rangeDates] = payload.data
		},
		setColorsReports: ({ colors }, { payload }) => {
			if (!colors[payload]) {
				// if (Object.keys(colors).length % availableColors.length === 0) {
				// 	indexSelectedColor = Object.keys(colors).length
				// } else {
				const indexSelectedColor =
					Object.keys(colors).length -
					parseInt(Object.keys(colors).length / availableColors.length) *
						availableColors.length
				// }

				colors[payload] = availableColors[indexSelectedColor]
				console.log({
					payload,
					colors,
					ev: colors[payload],
					indexSelectedColor,
				})
			} else {
				console.log({ payload, colors, ev: colors[payload] })
			}
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	getProjectsReport,
	setProjectsReport,
	getHistoryReport,
	setHistoryReport,
	setColorsReports,
} = reportsSlice.actions
export const selectProjectsReport = (state) => state.reportsSlice.projectsReport
export const selectHistoryReport = (state) => state.reportsSlice.historyReport
export const selectColors = (state) => state.reportsSlice.colors

export default reportsSlice.reducer
