import React from 'react'

//Material-UI
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { AnimatedText } from '../Reusable/animatedText'
import BaseModal from './baseModal'
import Swal from 'sweetalert2'

//Redux
import { create_project_user_action } from '../../redux/actions/projectsActions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
	root: {},
	input: { margin: '0px 20px 0px 20px' },
	submitButton: { alignSelf: 'stretch', margin: '0 20px 20px 20px' },
})

export default function ModalProjectUser(props) {
	const { open, setOpenModal, idCategory, headerName, form } = props
	// console.log('tamos aqui', props)

	// console.log(form)
	const c = useStyles()
	const dispatch = useDispatch()

	const [projectName, setProjectName] = React.useState('')




	React.useEffect(() => {
		setProjectName('')
	}, [open])

	return (
		<>
			<BaseModal open={false} setOpenModal={setOpenModal}>
				<Grid
					cointainer
					spacing={4}
					direction="row"
					justify="center"
					alignItems="stretch"
				>
					<AnimatedText open={true} fontSize={50}>
						<Grid item xs={12} style={{ marginBottom: '30px' }}>
							<Typography variant={'h5'}>{'Crear ' + headerName}</Typography>{' '}
							
						</Grid>
						<Grid item xs={12}>
							<form
								className={c.root}
								noValidate
								autoComplete="off"
								onSubmit={(e) => {
									e.preventDefault()
									e.stopPropagation()
									console.log('sumiteado')
									console.log({
										projectName,
									})
									let payloadProyecto = {
										project: projectName,
										public: true,
										category: idCategory,
									}
									//Agregar proyecto
									Swal.fire({
										title:
											'¿Estas seguro que deseas crear este ' +
											headerName +
											' con el nombre "' +
											projectName +
											'"?',
										showDenyButton: true,
										confirmButtonText: `Si`,
										denyButtonText: `No`,
									}).then((result) => {
										/* Read more about isConfirmed, isDenied below */
										if (result.isConfirmed) {
											console.log({ 'agregar proyecto': payloadProyecto })
											dispatch(
												create_project_user_action(
													payloadProyecto,
													setOpenModal,
												),
											)
										} else if (result.isDenied) {
										}
									})
								}}
							>
								<Grid
									container
									spacing={5}
									direction="column"
									justify="center"
									alignItems="stretch"
								>
									<Grid className={c.input} item xs={12}>
										<TextField
											required
											fullWidth
											label={headerName}
											value={projectName}
											type="text"
											onChange={(e) => setProjectName(e.target.value)}
											variant="outlined"
										/>
									</Grid>
									<Grid className={c.submitButton} item lg={12}>
										<Button
											fullWidth
											variant="contained"
											color="primary"
											type="submit"
										>
											{'Crear'}
										</Button>
									</Grid>
								</Grid>
							</form>
						</Grid>
					</AnimatedText>
				</Grid>
			</BaseModal>
		</>
	)
}
