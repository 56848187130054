import * as types from '../../const/actionTypes'

const initialState = {
	HistorialRegistrado: [],
}

export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_HISTORIAL_SUCCESS:
			console.log(">> SUCCESS <<")
			return {
				...state,
				HistorialRegistrado: action.data,
			}
		default:
			return { ...state }
	}
}
