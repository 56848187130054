import { useState, useEffect, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getWeekArrayByDay } from '../../utils/dates.js'
import ModalProjectUser from '../Reusable/modalProjectUser'
import { selectSettings } from '../../redux/reducers/settingsSlice.js'
import Alert from '@material-ui/lab/Alert';


import {
	getSettings as getSettingsAction,
} from '../../redux/reducers/settingsSlice'




import FormControl from '@material-ui/core/FormControl'

import TextField from '@material-ui/core/TextField'
import Collapse from '@material-ui/core/Collapse'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import IconButton from '@material-ui/core/IconButton'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { update_registers_action } from '../../redux/actions/registersActions.js'


function getModalStyle() {
	const top = 50
	const left = 50

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
		width: '70%',
		minWidth: '300px',
		maxHeight: '90%',
	}
}
const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		overflow: 'scroll',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		width: '100%',
	},
}))

const ModificaRegistro = (props) => {
	const { dataStructure, openModify, setOpenModify,  setDay, info, setInfo,  data, day, max, min,errMinMen  } = props;

	const user = JSON.parse(window.localStorage.getItem('user'))

	const classes = useStyles()
	const [formStructure, setFormStructure] = useState([])
	const [idCategorySelected, setIdCategorySelected] = useState(-1)
	const [openModalProject, setOpenModalProject] = useState(false)
	const [headerNameModal, setHeaderNameModal] = useState('')
	const [idCategory, setIdCategory] = useState();
	const [ msnErr, setMsnErr ] = useState(`No se puede editar por encima del máximo de ${max} horas permitidas`)

	const settings = useSelector((state) => selectSettings(state));
	const dispatch = useDispatch()
	

	const [values, setValues] = useState([])
	const [error, setError] = useState(false);
	const [ errorText, setErrorText ] = useState();

	useEffect(() => {
		dispatch(getSettingsAction())
	}, [])
	useEffect(() => {
		if (settings) {
			setValues(settings.map((setting) => setting.value))
		}
	}, [settings])
	
   
	const handleCloseMenu = (e, indexCategory, projectSelected) => {
		let newformStructure = [...formStructure]
		newformStructure[indexCategory].open = false
		newformStructure[indexCategory].anchorEl = null
		if (projectSelected) {
			projectSelected.hours = 0
			projectSelected.activities.forEach((activity) => {
				activity.hours = 0
			})
			newformStructure[indexCategory].registers.push(projectSelected)
		} else if (projectSelected === null) {
			setOpenModalProject(true)
			setHeaderNameModal(formStructure[indexCategory].category.level_1_header)
			setIdCategory(formStructure[indexCategory].category.id)
		
		}

		setFormStructure(newformStructure)
	}

	const submitModify = (e) => {
		let payload = []
		let user = JSON.parse(localStorage.getItem("user"));
		var submitDate = '';
		formStructure.forEach((category) => {
			category.registers.forEach((project) => {
				if (project.activities.length === 0) {
					
					if (Number(project.hours) > 0) {
						payload.push({
							project: project.id,
							activity: null,
							hours: project.hours,
							day:project.day === undefined ? moment(day).format('Y-MM-DD') : moment(project.day, 'DD/MM/YYYY"').format('Y-MM-DD'),
						})	
						submitDate = project.day;

						console.log("1st => ", payload)
					}
				} else {
					project.activities.forEach((activity) => {
						
						if (Number(activity.hours) >= 0) {
							payload.push({
								project: project.id,
								activity: activity.id,
								hours: activity.hours,
								//day: project.day === undefined ? moment(day).format('Y-MM-DD') : moment(project.day, 'DD/MM/YYYY"').format('Y-MM-DD'),
								day: project.day === undefined ? moment(day, 'DD/MM/YYYY').format('Y-MM-DD') : moment(project.day, 'DD/MM/YYYY').format('Y-MM-DD'),
							})
							//submitDate = project.day;
							submitDate = project.day === undefined ? moment(day, 'DD/MM/YYYY').format('Y-MM-DD') : moment(project.day, 'DD/MM/YYYY').format('Y-MM-DD')


							console.log("2nd project.day => ", project.day)
							console.log("2nd day => ", day)
							console.log("2nd formatted day => ", moment(day, 'DD/MM/YYYY').format('Y-MM-DD'))
							console.log("2nd ===> submitDate ", submitDate)
							console.log("2nd day ===> ", day)
						}
					})
				}
			})
		})

		console.log(day, "DAY JSX")

		let totales = 0;
		console.log("INVOKE GWABD with submitDate = ", submitDate )
		console.log("INVOKE GWABD with moment = ", moment(submitDate, 'Y-MM-DD"') )
		const days = getWeekArrayByDay(moment(submitDate, 'Y-MM-DD"'))
		console.log("GWABD => ", days)
		const selectDay =  moment(day, 'DD/MM/YYYY').format('Y-MM-DD')
		const startDate = moment(days[0], 'DD/MM/YYYY').format('Y-MM-DD')
		const endDate = moment(days[days.length - 1], 'DD/MM/YYYY').format(
			'Y-MM-DD',
		)
		for (let z = 0; z < payload.length; z++) {
			totales += Number(payload[z].hours)

			if (z === payload.length - 1) {
				if ( totales > max ) {
					console.log(totales, "TOTALES")
					console.log(max, "MAX")
					setError(true)
				} else {
					setError(false)
					console.log(selectDay, "SELECT_DAY JSX")
					dispatch(update_registers_action(payload, selectDay, setOpenModify, startDate, endDate, user.id))	
				}
			}
		}	
	}
	
	const isProjectSelected = (indexCategory, project) => {
		if (formStructure[indexCategory]) {	
			return formStructure[indexCategory].registers
				.map((project) => project.id)
				.includes(project.id)
		}
	}
	const getDayRegisters = () => {	
			if (day) {
				const dataNew = data[day]

				const editData = [...dataStructure];
			
		
			for (let i = 0; i < editData.length; i++) {
				for (let r = 0; r < dataNew.length; r++) {
					if ( editData[i].id === dataNew[r].category.id ) {
						editData[i] = dataNew[r].category
					}
				}
				
			}
			
			const dayRegister = dataNew.map(
				(element) => { return element }
			)
			const projects = dataNew.forEach((elem) => {
				let projectFiltered = elem.category.projects.filter(e => {
					return typeof e.register !== 'undefined'
				})
				const formModified = formStructure.map((form, i)  => {
					projectFiltered. forEach((regis) => {
						if (regis.category === form.category.id){
							if (regis.activities.length === 0) {
											form.registers.push({
												...regis,
												day: day,
												update: true,
												hours: regis.register.hours
											})
											} else {
												form.registers.push({
													...regis,
													day: day,
													update: true
												})
											}
										}
									})
								
									let activities = projectFiltered.forEach((act) => {
										let activity = act.activities
										let hoursRegister = act.register.activities
										let activityRegisters = activity.forEach(( activ) => {
											let hoursReg = hoursRegister.forEach(( hour ) => {
												if ( activ.id === hour.activity.id) {
													activ.hours = hour.hours
												}
											})
										})	
									})
								return form							 
							})
							
							setFormStructure(formModified)

						})
									
						const dataEdit = formStructure.map(datEd => {
							
							dataNew.map(daNew => {
								
								if (datEd.category.id === daNew.category.id) {
									datEd.category = daNew.category
								}
							})
							return datEd
						})

						setFormStructure(dataEdit)
					}
			}

	
	const getProjectFromRegisters = (indexCategory, idProject) => {
		return formStructure[indexCategory].registers.find((register) => {
			return register.id === idProject
		})
	}
	const getActivityFromRegisters = (indexCategory, idProject, idActivity) => {	
		let project = formStructure[indexCategory].registers.find((register) => {
			return register.id === idProject
		})
		return project.activities.find((activity) => {
			return activity.id === idActivity
		})
	}

	const removeFormStructure = () => {	
		for(var r=0; r<formStructure.length; r++) {
			formStructure[r].registers = [];
		}	
	}

	const checkTotalHours = (indexCategory, idProject) =>
	{
		let project = formStructure[indexCategory].registers.find((register) => {
			return register.id === idProject
		})
		
		console.log(project)

	var totalia = 0;
	for (var i = 0 ; i < project.activities.length ; i ++)
	{
		totalia += (project.activities[i].hasOwnProperty('hours') ? Number(project.activities[i].hours) :  0 )
		//console.log(project.activities[i].hours, "HOURS", i)
		//console.log(totalia, "TOTALIA")
	}
	
	return(totalia)
	}
	
	useEffect(() => {	
		setFormStructure(
			dataStructure.map((category) => {
				return {
					category: category,
					open: false,
					anchorEl: null,
					registers: [],
				}
			}),
		)	
	}, [dataStructure])

	useEffect(() => {
		if (!openModify) {
			setDay()
			setInfo()
		} 
	}, [openModify])

	const modalRef = useRef();
	



	return (
		<>
			<Modal
				open={(openModify && formStructure.length > 0)}
				ref={modalRef}
				onRendered={getDayRegisters}

				onClose={() => {
					setOpenModify(false)
					removeFormStructure()
					
				}}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				{/* { loading ? <p>Cargando...</p> : null} */}
				<div className={classes.paper} style={getModalStyle()}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography style={{ textAlign: 'center' }} variant="h6">
								{day}
							</Typography>
							<Divider />
							{error ?
								<>
									<Alert severity="error">{msnErr}</Alert>
									<Divider />
								</>
								: null
							}
						</Grid>
						
						{dataStructure.map((category, indexCategory) => {
							return (
								<>
									<Grid item xs={12}>
										<Typography
											style={{ textAlign: 'center', fontWeight: 'bold' }}
											variant="h6"
										>
											{category.category}
										</Typography>
										<Divider />
									</Grid>
									<Grid item xs={12}>
										<FormControl className={classes.formControl}>
											<Grid container spacing={2}>
												{category.projects.map((project) => {
													
													return (
														<>
															{isProjectSelected(indexCategory, project) && (
																<>
																	<Grid item xs={9}>
																		<TextField
																			id="standard-read-only-input"
																			fullWidth
																			label={project.project}
																			defaultValue={project.project}
																			InputProps={{
																				readOnly: true,
																			}}
																		/>
																	</Grid>
																	{project.activities.length === 0 ? (
																		
																		<Grid item xs={3}>
																			<TextField
																			
																				id="standard-read-only-input"
																				fullWidth
																				type="number"
																				label={'Horas'}
																				//defaultValue={1}
																				InputProps={{
																					inputProps: {
																						min: 0,
																						max: max,
																						step: min,
																					},
																				}}
																				value={
																					getProjectFromRegisters(
																						indexCategory,
																						project.id,
																					).hours
																				}

																				//onKeyDown={(event) => {
																				//	event.preventDefault();
																				//}}

																				onChange={(e) => {


																					e.target.value -= Number(e.target.value) % min 

																					//if(checkTotalHours(indexCategory, project.id) > max)
																					//{
																					//	setError(true)
																					//	return false
																					//}
																					

																					if (Number(e.target.value) >= 0) {	
																						let newformStructure = [
																							...formStructure,
																						]
																						
																						newformStructure[
																							indexCategory
																						].registers.map((register) => {
																						
																							if (register.id === project.id) {
																							
																								register.hours = e.target.value
																							}
																							
																							return register
																						})
																						
																						let totales = 0;
																						for (let z = 0; z < newformStructure.length; z++) {
																						
																							if(newformStructure[z].registers[0] )
																						for (let x = 0 ; x < newformStructure[z].registers[0].activities.length ; x++){

																									if (typeof newformStructure[z].registers[0].activities[x].hours === 'undefined' )
																									continue
																									totales += Number(newformStructure[z].registers[0].activities[x].hours)
																									console.log ("Activity => ", newformStructure[z].registers[0].activities[x].activity)
																						}
																						console.log("Accumulated Hours => ", totales)
																						if (totales > max) {
																								setError(true)
																								
																							} else {	
																								setError(false)
																							}
																						}
																						setFormStructure(newformStructure)
																						
																					} else {
																						
																						let newformStructure = [
																							...formStructure,
																						]
																						
																						newformStructure[
																							indexCategory
																						].registers.map((register) => {
																							if (register.id === project.id) {
																								register.hours = 0
																							}
																							return register
																						})

																						let totales = 0;
																						for (let z = 0; z < newformStructure.length; z++) {
																						
																						for (let x = 0 ; x < newformStructure[z].registers[0].activities.length ; x++){

																							if (typeof newformStructure[z].registers[0].activities[x].hours === 'undefined' )
																							continue
																									totales += Number(newformStructure[z].registers[0].activities[x].hours)
																									console.log ("Activity => ", newformStructure[z].registers[0].activities[x].activity)
																						}
																						console.log("Accumulated Hours => ", totales)
																						if (totales > max) {
																								setError(true)
																								
																							} else {	
																								setError(false)
																							}
																						}

																						setFormStructure(newformStructure)
																					}
																				}}
																			/>
																		</Grid>
																	) : (
																		<>
																			<Grid item xs={3}></Grid>
																			<Collapse in={true}>
																				<Grid container spacing={2}>
																					{ project.activities.map(
																						(activity) => {
																							return (
																								<>
																									<Grid item xs={2}></Grid>
																									<Grid item xs={7}>
																										<TextField
																											id="standard-read-only-input"
																											fullWidth
																											label={activity.activity}
																											defaultValue={
																												activity.activity
																											}
																											InputProps={{
																												readOnly: true,
																											}}
																										/>
																									</Grid>
																									<Grid item xs={3}>
																										<TextField
																										
																											id="standard-read-only-input"
																											fullWidth
																											type="number"
																											label={'Horas'}
																											//defaultValue={0}
																											InputProps={{
																												inputProps: {
																													min: 0,
																													max: max,
																													step: min,
																												},
																											}}

																											value={ 
																												getActivityFromRegisters(
																													indexCategory,
																													project.id,
																													activity.id,
																												).hours
																											}

																											//onKeyDown={(event) => {
																											//	event.preventDefault();
																											//}}

																											
																											onChange={(e) => {

																											//if(checkTotalHours(indexCategory, project.id) > max)
																											//{
																											//	e.target.value = 0
																											//	setError(true)
																											//	return false
																											//}
																											e.target.value -= Number(e.target.value) % min 
																											

																											if (Number(e.target.value) >= 0) {
																												
																												let newformStructure = [
																													...formStructure,
																												]
																												checkTotalHours(indexCategory, project.id)
																												newformStructure[
																													indexCategory
																												].registers.map((register) => {
																																															
																													if (register.id === project.id) {
																									
																															register.activities.map(act => {
																																
																																if (act.id === activity.id) {
																																
																																	act.hours = e.target.value
																																}
																																return act
																															})																																																						
																													}

																													return register
																													})


																													let totales = 0;
																													for (let z = 0; z < newformStructure.length; z++) {
																													
																													if(typeof newformStructure[z].registers[0] === 'undefined')
																													continue
																													for (let x = 0 ; x < newformStructure[z].registers[0].activities.length ; x++){
							
																														if (typeof newformStructure[z].registers[0].activities[x].hours === 'undefined' )
																														continue
																																totales += Number(newformStructure[z].registers[0].activities[x].hours)
																																console.log ("Activity => ", newformStructure[z].registers[0].activities[x].activity)
																													}
																													console.log("Accumulated Hours => ", totales)
																													if (totales > max) {
																															setError(true)
																															
																														} else {	
																															setError(false)
																														}
																													}

																														setFormStructure(newformStructure)
																													
																													} else {
																														
																														let newformStructure = [
																															...formStructure,
																														]
																														
																														newformStructure[
																															indexCategory
																														].registers.map((register) => {
																															
																															if (register.id === project.id) {
																																register.hours = null
																															}
																															return register
																														})

																											let totales = 0;
																						for (let z = 0; z < newformStructure.length; z++) {
																						
																						for (let x = 0 ; x < newformStructure[z].registers[0].activities.length ; x++){

																							if (typeof newformStructure[z].registers[0].activities[x].hours === 'undefined' )
																							continue
																									totales += Number(newformStructure[z].registers[0].activities[x].hours)
																									console.log ("Activity => ", newformStructure[z].registers[0].activities[x].activity)
																						}
																						console.log("Accumulated Hours => ", totales)
																						if (totales > max) {
																								setError(true)
																								
																							} else {	
																								setError(false)
																							}
																						}

																														setFormStructure(newformStructure)
																													}

																											}}
																										/>
																									</Grid>
																								</>
																							)
																						},
																					)}
																				</Grid>
																			</Collapse>
																		</>
																	)}
																</>
															)}
														</>
													)
												})}
											</Grid>
											<Grid item xs={12} style={{ textAlign: 'center' }}>
												<IconButton
													aria-label="Add Project Register"
													onClick={(e) => {
														setIdCategorySelected(indexCategory)

														let newformStructure = JSON.parse(
															JSON.stringify(formStructure),
														)
														
														newformStructure[indexCategory].open = true
														newformStructure[indexCategory].anchorEl =
															e.currentTarget
														setFormStructure(newformStructure)
													}}
												>
													<AddCircleOutlineIcon fontSize="large" />
												</IconButton>
												{formStructure.length > 0 && (
													<Menu 
														id="fade-menu"
														anchorEl={formStructure[indexCategory].anchorEl}
														keepMounted
														open={formStructure[indexCategory].open}
														onClose={(event) => {
															handleCloseMenu(event, idCategorySelected, false)
														}}
													>
														{category.projects.map((project) => {
															return (
																!isProjectSelected(
																	idCategorySelected,
																	project,
																) && (
																	<MenuItem
																		onClick={(event) => {
																			
																			handleCloseMenu(
																				event,
																				idCategorySelected,
																				project,
																			)
																		}}
																	>
																	
																		{project.project}
																	</MenuItem>
																)
															)
														})}
														{category.user_can_add_project && (
															<MenuItem
																onClick={(event) => {
																
																	handleCloseMenu(
																		event,
																		idCategorySelected,
																		null,
																	)
																}}
															>
																<AddCircleOutlineIcon fontSize="medium" />
																{'Agregar'}
															</MenuItem>
														)}
													</Menu>
												)}
											</Grid>
										</FormControl>
									</Grid>
								</>
							)
						})}
						<Grid item xs={12}>
							<Button
								fullWidth
								variant="contained"
								color="primary"
								onClick={() => {
									submitModify();
								}}
							>
								Editar
							</Button>
						</Grid>
					</Grid>
						{error ?
								<>	
									<Divider />
									<Alert severity="error">{msnErr}</Alert>
								</>
							: null
						}
				</div>
			</Modal>
			<ModalProjectUser
				open={openModalProject}
				setOpenModal={setOpenModalProject}
				idCategory={idCategory}
				headerName={headerNameModal}
			></ModalProjectUser>
			{ data  ? 
				<ModalProjectUser
				open={true}
				setOpenModal={setOpenModalProject}
				idCategory={idCategory}
				headerName={headerNameModal}
				form={formStructure}
			></ModalProjectUser>
			: 
			<ModalProjectUser
				open={openModalProject}
				setOpenModal={setOpenModalProject}
				idCategory={idCategory}
				headerName={headerNameModal}
			></ModalProjectUser>
			}
		</>
	)
}

export default ModificaRegistro


