import { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { getWeekArrayByDay } from '../../utils/dates.js'
import ModalProjectUser from '../Reusable/modalProjectUser'
import Alert from '@material-ui/lab/Alert';

//Actions
import { create_registers_action } from '../../redux/actions/registersActions'
// Selectors
// import { weekRegisters as weekRegistersSelector } from '../../redux/selectors/rootSelectors'

import FormControl from '@material-ui/core/FormControl'

import TextField from '@material-ui/core/TextField'
import Collapse from '@material-ui/core/Collapse'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import IconButton from '@material-ui/core/IconButton'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

function getModalStyle() {
	const top = 50
	const left = 50

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
		width: '70%',
		minWidth: '300px',
		maxHeight: '90%',
	}
}
const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		overflow: 'scroll',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		width: '100%',
	},
}))

const CrearRegistro = (props) => {
	const { dataStructure, openModal, setOpenModal, registerDay, max,msnErr, errMinMen, min } = props
	const classes = useStyles()
	const [formStructure, setFormStructure] = useState([])
	const [idCategorySelected, setIdCategorySelected] = useState(-1)
	const [openModalProject, setOpenModalProject] = useState(false)
	const [headerNameModal, setHeaderNameModal] = useState('')
	const [idCategory, setIdCategory] = useState()
	const [ permitido, setPermitido] = useState();
	const [ error, setError ] = useState(false);
	const [ errMen, setErrMen ] = useState( ` No se pueden añadir más de ${max} horas en total (el intervalo mínimo es ${min}`)
	const [ errMax, setErrMax ] = useState(false)
	const [ errMin, setErrMin ] = useState(`Mínimo de horas alcanzado`)
	const [ minHours, setMinHours ] = useState(0.5)

	const dispatch = useDispatch()
	const handleCloseMenu = (e, indexCategory, projectSelected) => {
		let newformStructure = [...formStructure]
		newformStructure[indexCategory].open = false
		newformStructure[indexCategory].anchorEl = null
		if (projectSelected) {
			projectSelected.hours = 0
			projectSelected.activities.forEach((activity) => {
				activity.hours = 0
			})
			newformStructure[indexCategory].registers.push(projectSelected)
		} else if (projectSelected === null) {


			setOpenModalProject(true)
			setHeaderNameModal(formStructure[indexCategory].category.level_1_header)
			setIdCategory(formStructure[indexCategory].category.id)
		}
		setFormStructure(newformStructure)
	}

	const submitRegister = (e) => {
		let payload = []
		formStructure.forEach((category) => {
			category.registers.forEach((project) => {
				if (project.activities.length === 0) {
					if (Number(project.hours) > 0) {
						payload.push({
							project: project.id,
							activity: null,
							hours: project.hours,
							day: moment(registerDay, 'DD/MM/YYYY"').format('Y-MM-DD'),
						})
					}
				} else {
					project.activities.forEach((activity) => {
						if (Number(activity.hours) > 0) {
							payload.push({
								project: project.id,
								activity: activity.id,
								hours: activity.hours,
								day: moment(registerDay, 'DD/MM/YYYY"').format('Y-MM-DD'),
							})
						}
					})
				}
			})
		})

		console.log("INVOKE GWABD with submitDate = ", registerDay )
		console.log("INVOKE GWABD with moment = ", moment(registerDay, 'DD/MM/YYYY"') )
		const days = getWeekArrayByDay(moment(registerDay, 'DD/MM/YYYY"'))

		const startDate = moment(days[0], 'DD/MM/YYYY').format('Y-MM-DD')
		const endDate = moment(days[days.length - 1], 'DD/MM/YYYY').format(
			'Y-MM-DD',
		)
		dispatch(create_registers_action(payload, setOpenModal, startDate, endDate))
	}
	const isProjectSelected = (indexCategory, project) => {
		if (formStructure[indexCategory]) {
			return formStructure[indexCategory].registers
				.map((project) => project.id)
				.includes(project.id)
		}
	}
	const getProjectFromRegisters = (indexCategory, idProject) => {
		return formStructure[indexCategory].registers.find((register) => {
			return register.id === idProject
		})
	}
	const getActivityFromRegisters = (indexCategory, idProject, idActivity) => {
		let project = formStructure[indexCategory].registers.find((register) => {
			return register.id === idProject
		})
		return project.activities.find((activity) => {
			return activity.id === idActivity
		})
	}

	const setHoursActivity = (hours, indexCategory, idProject, idActivity) => {
		let newformStructure = [...formStructure]
		if (idActivity === undefined) {
			newformStructure[indexCategory].registers.map((register) => {
				if (register.id === idProject) {
					register.hours = hours
				}
				return register
			})
			setFormStructure(newformStructure)
		} else {
			newformStructure[indexCategory].registers.map((register) => {
				if (register.id === idProject) {
					register.activities.map((activity) => {
						if (activity.id === idActivity) {
							activity.hours = hours
						}
					})
				}
				return register
			})
			setFormStructure(newformStructure)
		}
		for (let i = 0; i < newformStructure.length; i++) {
			const registers = newformStructure[i].registers;
			
			let sumaHoras = 0;
			for (let index = 0; index < registers.length; index++) {
				if (registers[index].activities.length !== 0) {
					for (let z = 0; z < registers[index].activities.length; z++) {
						sumaHoras += parseFloat(registers[index].activities[z].hours)
						
					}
				} else {
					sumaHoras+= parseFloat(registers[index].hours)	
				}
			}
			newformStructure[i].totHoursProj = sumaHoras
		}
		let totales = 0;
		for (let z = 0; z < newformStructure.length; z++) {
			totales += newformStructure[z].totHoursProj
			if (totales > max) {
				setError(true)
			} else {
				setError(false)
			}
		}
	}
	

	useEffect(() => {
		setFormStructure(
			dataStructure.map((category) => {
				return {
					category: category,
					open: false,
					anchorEl: null,
					registers: [],
				}
			}),
		)
	}, [openModal])
	return (
		<>
			<Modal
				open={openModal}
				onClose={() => {
					setOpenModal(false)
				}}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div className={classes.paper} style={getModalStyle()}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography style={{ textAlign: 'center' }} variant="h6">
								{registerDay}
							</Typography>
							<Divider />
							{error ?
								<>
									<Alert severity="error">{msnErr}</Alert>
									<Divider />
								</>
								: null
							}
						</Grid>
						{dataStructure.map((category, indexCategory) => {
							if (category.projects.length === 0) {
								return <></>
							}
							return (
								<>
									<Grid item xs={12}>
										<Typography
											style={{ textAlign: 'center', fontWeight: 'bold' }}
											variant="h6"
										>
											{category.category}
										</Typography>
										<Divider />
									</Grid>
									<Grid item xs={12}>
										<FormControl className={classes.formControl}>
											<Grid container spacing={2}>
												{category.projects.map((project) => {
													return (
														<>
															{isProjectSelected(indexCategory, project) && (
																<>
																	<Grid item xs={9}>
																		<TextField
																			id="standard-read-only-input"
																			fullWidth
																			label={project.project}
																			defaultValue={project.project}
																			InputProps={{
																				readOnly: true,
																			}}
																		/>
																	</Grid>
																	{project.activities.length === 0 ? (
																	
																		<Grid item xs={3}>
																			<TextField
																				error={errMax}
																				helperText={errMin ? errMinMen : null}
																				id="standard-read-only-input"
																				fullWidth
																				type="number"
																				label={'Horass'}
																				defaultValue={0}
																				InputProps={{
																					inputProps: {
																						min: 0,
																						max: max,
																						step: min,
																					},
																				}}
																				value={
																					getProjectFromRegisters(
																						indexCategory,
																						project.id,
																					).hours
																				}

																				//onKeyDown={(event) => {
																				//	event.preventDefault();
																				//}}

																				onChange={(e) => {

																					e.target.value -= Number(e.target.value) % min 
																					
																					//if(error)
																					//	e.target.value -= min;

																					// if (Number(e.target.value) > 0 && min !== null && Number(e.target.value) % min !== 0.5 || 0 ) {
																					//	setErrMin(true)
																					//} else if (Number(e.target.value) === 0 || min !== 0 && Number(e.target.value) % min === 0.5 || 0) {
																					//	setErrMin(false)
																					//}
																			
																					if (Number(e.target.value) >= 0) {
																						setHoursActivity(
																							e.target.value,
																							indexCategory,
																							project.id
																						)
																					} else {
																						setHoursActivity(
																							0,
																							indexCategory,
																							project.id
																						)
																					}
																				}}
																			/>
																		</Grid>
																	) : (
																		<>
																			<Grid item xs={3}></Grid>
																			<Collapse in={true}>
																				<Grid container spacing={2}>
																					{project.activities.map(
																						(activity) => {
																							return (
																								<>
																									<Grid item xs={2}>&nbsp;</Grid>
																									<Grid item xs={7}>
																										<TextField
																											id="standard-read-only-input"
																											fullWidth
																											label={activity.activity}
																											defaultValue={
																												activity.activity
																											}
																											InputProps={{
																												readOnly: true,
																											}}
																										/>
																									</Grid>
																									<Grid item xs={3}>
																										<TextField
																											error={errMax}
																											helperText={errMin ? errMinMen : null}
																											id="standard-read-only-input"
																											fullWidth
																											type="number"
																											label={'Horas'}
																											defaultValue={0}
																											
																											InputProps={{
																												inputProps: {
																													min: 0,
																													max: max,
																													step: min,
																												},
																											}}
																											value={
																												getActivityFromRegisters(
																													indexCategory,
																													project.id,
																													activity.id,
																												).hours
																											}

																											//onKeyDown={(event) => {
																											//	event.preventDefault();
																											//}}

																											onChange={(e) => {


																												
																												e.target.value -= Number(e.target.value) % min 

																												//if(error)
																												//	e.target.value -= min;
																													
																												
																												
																												// if (Number(e.target.value) > 0 && min !== null &&  Number(e.target.value) < minHours ) {
																												//	setErrMin(true)
																												//} else if (Number(e.target.value) === 0 || min !== null && Number(e.target.value) > minHours) {
																												//	setErrMin(false)
																												//}

																												if (Number(e.target.value) >= 0) 
																												{
																													setHoursActivity(
																														e.target.value,
																														indexCategory,
																														project.id,
																														activity.id,
																													)
																												} else {
																													setHoursActivity(
																														0,
																														indexCategory,
																														project.id,
																														activity.id,
																													)
																												}
																											}}
																										/>
																									</Grid>
																								</>
																							)
																						},
																					)}
																				</Grid>
																			</Collapse>
																			</>
																	)}
																</>
															)}
														</>
													)
												})}
											</Grid>
											<Grid item xs={12} style={{ textAlign: 'center' }}>
												<IconButton
													aria-label="Add Project Register"
													onClick={(e) => {
														setIdCategorySelected(indexCategory)

														let newformStructure = JSON.parse(
															JSON.stringify(formStructure),
														)
														newformStructure[indexCategory].open = true
														newformStructure[indexCategory].anchorEl =
															e.currentTarget
														setFormStructure(newformStructure)
													}}
												>
													<AddCircleOutlineIcon fontSize="large" />
												</IconButton>
												{formStructure.length > 0 && (
													<Menu
														id="fade-menu"
														anchorEl={formStructure[indexCategory].anchorEl}
														keepMounted
														open={formStructure[indexCategory].open}
														onClose={(event) => {
															handleCloseMenu(event, idCategorySelected, false)
														}}
													>
														{category.projects.map((project) => {
															return (
																!isProjectSelected(
																	idCategorySelected,
																	project,
																) && (
																	<MenuItem
																		onClick={(event) => {
																		
																			handleCloseMenu(
																				event,
																				idCategorySelected,
																				project,
																			)
																		}}
																	>
																		{project.project}
																	</MenuItem>
																)
															)
														})}
														{category.user_can_add_project && (
															<MenuItem
																onClick={(event) => {
																	
																	handleCloseMenu(
																		event,
																		idCategorySelected,
																		null,
																	)
																}}
															>
																<AddCircleOutlineIcon fontSize="medium" />
																{'Agregar'}
															</MenuItem>
														)}
													</Menu>
												)}
											</Grid>
										</FormControl>
									</Grid>
								</>
							)
						})}
						<Grid item xs={12}>
							<Button
								disabled={error}
								fullWidth
								variant="contained"
								color="primary"
								onClick={() => {
									submitRegister()
								}}
							>
								Guardar
							</Button>
						</Grid>
					</Grid>
				</div>
			</Modal>
			<ModalProjectUser
				open={openModalProject}
				setOpenModal={setOpenModalProject}
				idCategory={idCategory}
				headerName={headerNameModal}
			></ModalProjectUser>
		</>
	)
}

export default CrearRegistro
