import * as types from '../../const/actionTypes'

const initialState = {
	totaHours: [],
}

export default function (state = initialState, action) {
	switch (action.type) {
		case types.TOTAL_HOURS_PROJECT_SUCCESS:
			return {
				...state,
				totalHours: action.payload,
			}
		default:
			return {
				...state,
			}
	}
}
