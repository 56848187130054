import { useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import Grow from '@material-ui/core/Grow'
import Chip from '@material-ui/core/Chip'
import { useTheme } from '@material-ui/core/styles'
import ProfileAvatar from '../Reusable/profileAvatar'
import Tooltip from '@material-ui/core/Tooltip'
import { useSelector } from 'react-redux'
import Skeleton from '@material-ui/lab/Skeleton'

import { generalLoading } from '../../redux/selectors/rootSelectors'

const useStyles = makeStyles((theme) => ({
	root: ({ hover }) => ({}),
	paper: ({ hover }) => ({
		textAlign: 'center',
		color: hover ? 'black' : theme.palette.text.secondary,
		transform: hover ? 'scale(1.1)' : 'scale(1)',
		transition: 'transform 1s',
		cursor: 'pointer',
	}),
	date: {
		textTransform: 'uppercase',
		color: 'white',
		fontWeight: 'bold',
	},
	header: {
		backgroundColor: theme.palette.primary.main,
	},
	body: {
		alignSelf: 'center',
		alignItems: 'center',
	},
	addIcon: {
		fontSize: '9rem',
	},
	bodyProject: {},
	badgeCategory: {
		backgroundColor: theme.palette.primary.dark,
		color: 'white',
		width: '90%',
		margin: '10px 0 0 0',
		fontWeight: 'bold',
	},
	badgeProject: {
		backgroundColor: theme.palette.primary.light,
		color: 'white',
		width: '90%',
		margin: '7px 0 0 0',
	},
	badgeSector: {
		backgroundColor: '#67b6e0',
		color: 'white',
		width: '90%',
		margin: '5px 0 0 0',
	},
	badgeIcon: {
		borderRadius: '50px',
		color: 'green',
		backgroundColor: '#bdbdbd',
	},
	badgeAvatar: {
		color: 'white',
		height: '24px',
		width: '24px',
	},
	skeleton: {
		width: '90%',
		height: 40,
		marginLeft: '5%',
	},
}))
const PaperDay = (props) => {
	const { infoDay, timeout, workInfo, onClick } = props

	const [hover, setHover] = useState(false)
	const theme = useTheme()
	const loadingGeneral = useSelector(generalLoading)

	const classes = useStyles({ hover })


	return (
		<Grow
			in={true}
			style={{ transformOrigin: '0 0 0' }}
			{...(true ? { timeout: timeout ? timeout : 1000 } : {})}
		>
			<div onClick={onClick}>
				<Paper
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
					className={classes.paper}
				>
					<Grid
						container
						direction="row"
						justifyContent="center"
						alignItems="center"
						spacing={0}
					>
						<Grid className={classes.header} item xs={12}>
							<Typography className={classes.date} variant="subtitle1">
								{infoDay}
							</Typography>
							<Divider variant="middle" />
						</Grid>
						{loadingGeneral ? (
							<>
								<Grid
									item
									xs={12}
									style={{ height: '30vh', overflowY: 'scroll' }}
								>
									<Skeleton animation="wave" className={classes.skeleton} />
									<Skeleton animation="wave" className={classes.skeleton} />
									<Skeleton animation="wave" className={classes.skeleton} />
									<Skeleton animation="wave" className={classes.skeleton} />
									<Skeleton animation="wave" className={classes.skeleton} />
									<Skeleton animation="wave" className={classes.skeleton} />
								</Grid>
							</>
						) : (
							<Grid
								container
								direction="row"
								justifyContent="center"
								alignItems="stretch"
								spacing={0}
								style={{ height: '30vh', overflowY: 'scroll' }}
							>
								{!workInfo ? (
									<Grid className={classes.body} item xs={12}>
										<AddIcon className={classes.addIcon} />
										<Typography variant="subtitle2" gutterBottom>
											Agregar horas de trabajo
										</Typography>
									</Grid>
								) : (
									<>
										{workInfo.map(({ category }, i) => {
											return (
												<>
													<Grid className={classes.bodyProject} item xs={12}>
														<Tooltip title={category.category}>
															<Chip
																className={classes.badgeCategory}
																avatar={
																	<ProfileAvatar
																		styleProps={classes.badgeAvatar}
																		size={'12px'}
																	/>
																}
																label={category.category}
																clickable
															/>
														</Tooltip>
													</Grid>
													{category.projects.map((project) => {
														if (project.register) {
															const title =
																project.project +
																(project.register
																	? ' (' + project.register.hours + ' horas)'
																	: '')
															return (
																<>
																	<Grid item xs={1}></Grid>
																	<Grid item xs={11}>
																		<Tooltip title={title}>
																			<Chip
																				className={classes.badgeProject}
																				avatar={
																					<ProfileAvatar
																						styleProps={classes.badgeAvatar}
																						size={'12px'}
																					/>
																				}
																				label={title}
																				clickable
																			/>
																		</Tooltip>
																	</Grid>
																	{project.register.activities &&
																		project.register.activities.map(
																			(activity) => {
																				const title =
																					activity.activity.activity +
																					(activity.hours
																						? ' (' + activity.hours + ' horas)'
																						: '')
																				return (
																					<>
																						<Grid item xs={2}></Grid>
																						<Grid item xs={10}>
																							<Tooltip title={title}>
																								<Chip
																									className={
																										classes.badgeSector
																									}
																									avatar={
																										<ProfileAvatar
																											styleProps={
																												classes.badgeAvatar
																											}
																											size={'12px'}
																										/>
																									}
																									label={title}
																									clickable
																								/>
																							</Tooltip>
																						</Grid>
																					</>
																				)
																			},
																		)}
																</>
															)
														}
													})}
												</>
											)
										})}
									</>
								)}
							</Grid>
						)}
					</Grid>
				</Paper>
			</div>
		</Grow>
	)
}
export default PaperDay
