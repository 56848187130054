import * as types from '../../const/actionTypes'

export const get_registers_action = (payload) => {
	return {
		type: types.GET_REGISTERS,
		payload,
	}
}

export const create_registers_action = (
	payload,
	setOpenModal,
	startDate,
	endDate,
) => {
	console.log(payload, setOpenModal, startDate, endDate, 'calen buse 2')
	return {
		type: types.CREATE_REGISTERS,
		payload,
		setOpenModal,
		startDate,
		endDate,
	}
}

export const update_registers_action = (
	payload,
	selectedDay,
	setOpenModal,
	startDate,
	endDate,
	user
) => {
	return {
		type: types.UPDATE_REGISTERS_SUCCESS,
		payload,
		selectedDay,
		setOpenModal,
		startDate,
		endDate,
		user,
	}
}

export const delete_day_registers_action = (payload, startDate, endDate) => {
	console.log(payload, startDate, endDate)
	return {
		type: types.DELETE_DAY_REGISTERS,
		payload,
		startDate,
		endDate,
	}
}
