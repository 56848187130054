import * as types from '../../const/actionTypes'

// let user = JSON.parse(localStorage.getItem("user"));
const initialState = {
	WeekRegisters: [],
	RegistersData: [],
}

export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_REGISTERS_SUCCESS:
			return {
				...state,
				WeekRegisters: action.payload,
			}
		case types.UPDATE_REGISTERS_SUCCESS:
			return {
				...state,
			}
		default:
			return { ...state }
	}
}

export const selectHistoryRegister = (state) => state.initialState.RegisterData
// export default function
