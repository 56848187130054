import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
const ProfileAvatar = (props) => {
	const user = JSON.parse(window.localStorage.getItem('user'))
	const { size, styleProps } = props
	return (
		<Avatar className={styleProps}>
			<Typography style={{ fontSize: size }}>
				{user &&
					user.name
						.split(' ')
						.map((name) => name.charAt(0))
						.join('')
						.substring(0, 2)}
			</Typography>
		</Avatar>
	)
}
export default ProfileAvatar
