import * as types from '../../const/actionTypes'

// let user = JSON.parse(localStorage.getItem("user"));
const initialState = {
	CategoriesInfo: [],
	MyCategoriesInfo: [],
}

export default function (state = initialState, action) {
	console.log(action)
	switch (action.type) {
		case types.GET_CATEGORIES_SUCCESS:
			return {
				...state,
				CategoriesInfo: action.payload,
			}
		case types.GET_MY_CATEGORIES_SUCCESS:
			return {
				...state,
				MyCategoriesInfo: action.payload,
			}
		default:
			return { ...state }
	}
}
