import * as types from '../../const/actionTypes';

const initialState = {
    notifications: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case types.NOTIFICATIONS_USER_SUCCESS:
            return {
                ...state,
                notifications: action.payload
            }
        default: 
            return { ...state }
    }
}