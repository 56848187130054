import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from '../components/Routes/privateRoute'
import Calendario from '../views/Calendario/calendario.jsx'
const Login = React.lazy(() => import('../views/Login/login.jsx'))
const PasswordForgot = React.lazy(() =>
	import('../views/Login/passwordForgot.jsx'),
)

const PasswordRestore = React.lazy(() =>
	import('../views/Login/passwordRestore.jsx'),
)

const Mpa = React.lazy(() => import('../views/Mpa/mpa.jsx'))
const Dashboard = React.lazy(() => import('../views/Dashboard/dashboard.jsx'))
const Usuarios = React.lazy(() => import('../views/Usuarios/usuarios.jsx'))
const Proyectos = React.lazy(() => import('../views/Proyectos/proyectos.jsx'))
const Reportes = React.lazy(() => import('../views/Reportes/reportes.jsx'))
const Perfil = React.lazy(() => import('../views/Perfil/perfil.jsx'))
const Ajustes = React.lazy(() => import('../views/Ajustes/ajustes.jsx'))
//const Calendario = React.lazy(() =>
//	import('../views/Calendario/calendario.jsx'),
//)

export default function App() {
	return (
		<Router>
			<div>
				<Switch>
					<React.Suspense fallback={<div />}>
						<PrivateRoute exact path="/" component={Dashboard} />
						<PrivateRoute exact path="/calendario" component={Calendario} />
						<PrivateRoute exact path="/reportes" component={Reportes} />
						<PrivateRoute exact path="/perfil" component={Perfil} />
						<PrivateRoute exact path="/proyectos" component={Proyectos} />
						<PrivateRoute exact path="/usuarios" component={Usuarios} />
						<PrivateRoute exact path="/ajustes" component={Ajustes} />
						<PrivateRoute exact path="/mpa" component={Mpa} />

						<Route exact path="/login">
							<Login />
						</Route>
						<Route exact path="/password-restore/:token">
							<PasswordRestore />
						</Route>
						<Route exact path="/password-forgot">
							<PasswordForgot />
						</Route>
					</React.Suspense>
				</Switch>
			</div>
		</Router>
	)
}
