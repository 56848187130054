import { Children } from 'react'
import { useSpring, animated, config, useTrail, a } from 'react-spring'
export const AnimatedText = ({ children }) => {
	const props = useSpring({
		to: { opacity: 1 },
		from: { opacity: 0 },
		reset: false,
		delay: 200,
		config: config.molasses,
marginBottom:"100px"		
	})

	return <animated.div style={props}>{children}</animated.div>
}

export const Trail = ({ open, children, fontSize }) => {
	const items = Children.toArray(children)
	const trail = useTrail(items.length, {
		config: { mass: 5, tension: 2000, friction: 200 },
		opacity: open ? 1 : 0,
		x: open ? 0 : 20,
		height: fontSize?fontSize : 0,
		from: { opacity: 0, x: 20, height: 0 },
		duration: 10,
		delay: 0,
		
	})
	return (
		<div>
			{trail.map(({ height, ...style }, index) => (
				<a.div key={index} style={style}>
					<a.div style={{ height }}>{items[index]}</a.div>
				</a.div>
			))}
		</div>
	)
}
