import React from 'react'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		overflow: 'auto',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		width: '100%',
	},
}))

export default function BaseModal(props) {
	const { open, setOpenModal, children } = props
	const classes = useStyles()

	

	function getModalStyle() {
		const top = 50
		const left = 50

		return {
			top: `${top}%`,
			left: `${left}%`,
			transform: `translate(-${top}%, -${left}%)`,
			width: '70%',
			minWidth: '300px',
			maxHeight: '90%',
		}
	}

	return (
		<>
			<Modal
				open={open}
				onClose={() => {
					setOpenModal(false)
				}}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div className={classes.paper} style={getModalStyle()}>
					{children}
				</div>
			</Modal>
		</>
	)
}
