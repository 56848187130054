import { put, call, takeLatest } from 'redux-saga/effects'
import { apiCall } from '../apiCall'
import * as types from '../../const/actionTypes'
import * as Services from '../services/authServices.js'
import Swal from 'sweetalert2'
import moment from 'moment'

export function* get_registers_sagas({ payload }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			'registers/registers/' +
				'?startDate=' +
				payload.startDate +
				'&endDate=' +
				payload.endDate +
				'',
			{},
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'GET',
		)
		if (res.status === 200) {
			let registers = {}
			res.data.forEach((register) => {
				// console.log({ registers })
				register.day = moment(register.day, 'Y-MM-DD').format('DD/MM/YYYY')
				// console.log({
				// 	registers,
				// 	register,
				// 	day: register.day,
				// 	day2: register['day'],
				// })

				if (Object.keys(registers).indexOf(register.day) === -1) {
					registers[register.day] = []
				}
				register.hours = Number(register.hours)
				registers[register.day].push(register)
			})
			let ordererRegisters = {}

		

			//Cada dia en registers
			Object.keys(registers).forEach((day) => {
				ordererRegisters[day] = []
				//Agregar un elemento por cada categoria sin repetir
				let uniqueCategoriesArray = [
					...new Set(
						registers[day].map((register) => JSON.stringify(register.category)),
					),
				].map((register) => JSON.parse(register))
				uniqueCategoriesArray.forEach((category) => {
					ordererRegisters[day].push({ category })
				})
				//por cada categoria en el dia
				ordererRegisters[day].forEach((category, indexCategory) => {
					category.category.projects.forEach((project, indexProject) => {
						registers[day].forEach((register, indexRegister) => {
							if (project.id === register.project.id) {
								if (!register.activity) {
									category.category.projects[indexProject].register =
										registers[day][indexRegister]
								} else {
									let newRegister = JSON.parse(JSON.stringify(registers))[day]

									if (!category.category.projects[indexProject].register) {
										category.category.projects[indexProject].register = [
											...registers[day],
										][indexRegister]
										category.category.projects[indexProject].register.hours =
											registers[day][indexRegister].hours
										category.category.projects[
											indexProject
										].register.activities = []
									} else {
										category.category.projects[indexProject].register.hours +=
											registers[day][indexRegister].hours
									}

									category.category.projects[
										indexProject
									].register.activities.push(newRegister[indexRegister])
								}
							}
						})
					})
				})
			})
			// console.log({ registers, ordererRegisters })

			yield put({
				type: types.GET_REGISTERS_SUCCESS,
				payload: ordererRegisters,
			})
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

export function* create_registers_sagas({
	payload,
	setOpenModal,
	startDate,
	endDate,
}) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			'registers/registers/',
			payload,
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'POST',
		)
		if (res.status === 201) {
			yield put({ type: types.CREATE_PROJECT_SUCCESS })
			Swal.fire({
				icon: 'success',
				title: 'Éxito',
				text: 'Se creó correctamente',
			}).then(() => {
				setOpenModal(false)
			})
			yield put({ type: types.GET_REGISTERS, payload: { startDate, endDate } })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e.response.data.detail ? e.response.data.detail : e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

export function* delete_day_registers_sagas({ payload, startDate, endDate }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {

		console.log(payload, "DELETE PAYLOAD")
		const res = yield call(
			apiCall,
			'registers/delete/?date=' + payload,
			{},
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'DELETE',
		)
		if (res.status === 200) {
			Swal.fire({
				icon: 'success',
				title: 'Éxito',
				text: 'Se borró correctamente',
			}).then(() => {})
			yield put({ type: types.GET_REGISTERS, payload: { startDate, endDate } })
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e.response.data.detail ? e.response.data.detail : e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}
export function* update_registers_action_sagas({
	payload,
	selectedDay,
	setOpenModal,
	startDate,
	endDate,
	user,
}) {


	console.log(selectedDay, "SELECTED_DAY")
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		console.log(user, 'UPDATE_REGISTERS')
		const res = yield call(
			apiCall,
			'registers/update/day/' + selectedDay + '/' + user + '/',
			payload,
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'PUT',
		)
		if (res.status === 200) {
			yield put({
				type: types.GET_REGISTERS,
				payload: { startDate, endDate },
			})
			Swal.fire({
				icon: 'success',
				title: 'Éxito',
				text: 'Se editó correctamente',
			}).then(() => {
				setOpenModal(false)
			})
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e.response.data.detail ? e.response.data.detail : e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

export default function* projectsSaga() {
	yield takeLatest(types.GET_REGISTERS, get_registers_sagas)
	yield takeLatest(types.CREATE_REGISTERS, create_registers_sagas)
	yield takeLatest(types.DELETE_DAY_REGISTERS, delete_day_registers_sagas)
	yield takeLatest(
		types.UPDATE_REGISTERS_SUCCESS,
		update_registers_action_sagas,
	)
}
