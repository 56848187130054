import * as types from '../../const/actionTypes'

const initialState = {
	payload: [],
	data: [],
}



export default function (state = initialState, action) {
	
	switch (action.type) {
		case types.GET_HISTORIAL:
			return {
				...state,
				payload: action.payload,
			}
		// case types.GET_HISTORIAL_SUCCESS:
		// 	return {
		// 		...state,
		// 		HistoricalRegistrado: action.payload,
		// 	}
		default:
			return { ...state }
	}
}
