import axios from 'axios'
import Swal from 'sweetalert2'
//import { store } from "../store/store";
import ApiURL from '../const/ApiURL.js'

const getErrorText = (error) => {
	let errorMessage = ''
	if (error.response.data.detail) {
		errorMessage = error.response.data.detail
	} else if (error.response.data.email) {
		errorMessage = error.response.data.email[0]
	} else {
		errorMessage = 'Error desconocido'
	}
	return errorMessage
}

axios.interceptors.response.use(
	function (response) {
		return response
	},
	function async(error) {
		if (error.request.responseURL !== ApiURL + 'auth/token/verify/') {
			console.log({ error })

			Swal.fire({
				title: 'Error!',
				text: getErrorText(error),
				icon: 'error',
				confirmButtonText: 'Cerrar',
			})

			if (error.response.status === 401) {
				window.location.href = '/login'
				window.localStorage.clear()
			}
		}
		return Promise.reject(error)
	},
)

export const apiCall = (url, data, headers, method) =>
	axios({ method, url: ApiURL + url, data, headers })
// .catch((error) =>
// 	Swal.fire({
// 		title: 'Error!',
// 		text: error.response.data.detail,
// 		icon: 'error',
// 		confirmButtonText: 'Cerrar',
// 	}),
// )
