import { all } from 'redux-saga/effects'
import authRegisterSaga from './authRegisterSagas'
import authSagas from './authSagas'
import projectsSagas from './projectsSagas'
import registersSagas from './registersSagas'
import reportsSagas from './reportsSagas'
import settingsSagas from './settingsSagas'

export default function* rootSaga(params) {
	yield all([
		authSagas(),
		projectsSagas(),
		registersSagas(),
		reportsSagas(),
		settingsSagas(),
		authRegisterSaga(),
	])
}
