import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Router from './routes/router'
import reportWebVitals from './reportWebVitals'
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import moment from 'moment'
import 'moment/locale/es'

import { Provider } from 'react-redux'
// store all the data for the App
import configureStore from './redux/store/store'
//moment config
moment.locale('es')

//Redux config

const store = configureStore()
if (localStorage.getItem('access')) {
	store.dispatch({
		type: 'LOGIN_SUCCESS',
		tokens: {
			data: {
				access: localStorage.access,
				refresh: localStorage.refresh,
			},
		},
	})
}

if (process.env.NODE_ENV === 'production') {
	console.log = () => {}
	console.error = () => {}
	console.warn = () => {}
}
let theme = createTheme({
	palette: {
		primary: {
			// Azul M y R
			main: '#006EAB',
		},
		secondary: {
			// Negro M y R
			main: '#000000',
		},
		error: {
			main: '#f44336',
		},
		blues: {
			main: '#007bbd',
			1: '#fafdff',
			2: '#ebf8ff',
			3: '#cfeeff',
			4: '#a1deff',
			5: '#5cc6ff',
			6: '#00a3fb',
			7: '#007bbd',
			8: '#005989',
		},
		grays: {
			1: '#fdfdfd',
			2: '#f6f6f6',
			3: '#eaeaea',
			4: '#d6d6d6',
			5: '#bcbcbc',
			6: '#9b9b9b',
			7: '#767676',
			8: '#555555',
		},

		badgets: {
			primary: { main: '#006EAB' },
			secundary: { main: '#000000' },
			tertiary: { main: '#aab7b8' },
		},
	},
	textColor: {
		primary: '#546270',
		secondary: '#aab7b8',
	},
})
theme = responsiveFontSizes(theme)

ReactDOM.render(
	<React.StrictMode>
		<head>
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1, user-scalable=0, maximum-scale=1, minimum-scale=1"
			/>
		</head>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Router />
			</ThemeProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
