import { put, call, takeLatest } from 'redux-saga/effects'
import { apiCall } from '../apiCall'
import * as types from '../../const/actionTypes'
import * as Services from '../services/authServices.js'
import Swal from 'sweetalert2'
import {
	getProjectsReport,
	setProjectsReport,
	getHistoryReport,
	setHistoryReport,
} from '../../redux/reducers/reportsSlice'

export function* get_projects_report_sagas({ payload }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			`reports/projects-report/?startDate=${payload.startDate}&endDate=${payload.endDate}`,
			{},
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'GET',
		)
		if (res.status === 200) {
			yield put({
				type: setProjectsReport.type,
				payload: {
					rangeDates: `${payload.startDate}_${payload.endDate}`,
					data: res.data,
				},
			})
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

export function* get_history_report_sagas({ payload }) {
	yield put({ type: types.SET_API_LOADING, payload: true })
	try {
		const res = yield call(
			apiCall,
			`reports/history-report/?startDate=${payload.startDate}&endDate=${payload.endDate}`,
			{},
			{
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + Services.getAccessToken(),
			},
			'GET',
		)
		if (res.status === 200) {
			yield put({
				type: setHistoryReport.type,
				payload: {
					rangeDates: `${payload.startDate}_${payload.endDate}`,
					data: res.data,
				},
			})
		}
	} catch (e) {
		yield put({ type: types.SET_API_ERROR, e })
		Swal.fire({
			icon: 'error',
			title: 'Algo salio mal',
			text: e,
		})
	}
	yield put({ type: types.SET_API_LOADING, payload: false })
}

export default function* authSaga() {
	yield takeLatest(getProjectsReport.type, get_projects_report_sagas)
	yield takeLatest(getHistoryReport.type, get_history_report_sagas)
}
