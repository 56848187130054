import moment from 'moment'

export const getWeekArrayByDay = (weekDay) => {
	var startOfWeek = moment(weekDay).startOf('isoWeek')
	var endOfWeek = moment(weekDay).endOf('isoWeek')
	 //console.log({ weekDay, startOfWeek, endOfWeek })
	var days = []
	var day = startOfWeek
	while (day <= endOfWeek) {
		days.push(day.toDate())
		day = day.clone().add(1, 'd')
	}
	// console.log(days)
	return days.map((day) => {
		return moment(day).format('DD/MM/YYYY')
	})
}
